import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom'
import './index.scss';

import LOGO from "../../public/img/logo.png"
import img2 from './icon/copyright.png'
const CopyRight = (props) => {
const base = "/hoopscomeshome";
  
  const handleClick=(url)=>{
    window.open(url);
  };

  return (
    <div className='tail'>
      <div className='copy-contain'>
        <div className="navs">
          <Link to={base+'/play'} className={classNames('nav', { active: props.active === 0})}>PLAY</Link>
          <Link to={base+'/review'} className={classNames('nav', { active: props.active === 1})}>REVIEW</Link>
          <Link to={base+'/contact'} className={classNames('nav', { active: props.active === 2})}>CONTACT</Link>
          <Link to={base+'/introduction'} className={classNames('nav', { active: props.active === 3})}>INTR0DUCTION</Link>
        </div>
        <div className='link-list'>
          <div className="icon ins" onClick={()=>handleClick('https://www.instagram.com/thekingofunderdogs/')}  />
          <div className="icon youtube" onClick={()=>handleClick('https://www.youtube.com/channel/UCH94ooPQOK4jTZz_UK7G1RA')}/>
          <div className="icon douyin" onClick={()=>handleClick('https://vm.tiktok.com/udYh66/')}/>
          <div className="icon tw" onClick={()=>handleClick('https://twitter.com/KU1on1')} />
          <div className="icon facebook" onClick={()=>handleClick('https://www.facebook.com/basketballthekingofunderdogs/')}/>
        </div>

        <div className='copy-text'>
          <img src={img2} className="logo" />
        </div>
        <img src={LOGO} className="logo" />

        
      </div>
    </div>
  );
};


export default CopyRight;