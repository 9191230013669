import React from "react";
import "./index.scss";
// import host from '@api/host';
import Logo from "../../public/img/logo.png";
import { Link } from "react-router-dom";
function Nav(props) {
const base = "/hoopscomeshome";
  return (
    <div>
      <div className="firstClassNav">
        <div className="nav">
          <ul>
            <div className="home-link">
              <Link to={ base}>
                <img className="logo" src={Logo} alt="" />
              </Link>
            </div>
            <div
              onClick={() => {
                // sa.track('LrwOverseasNavClick_W', {
                //   platform: 'PC',
                //   nav:'PLAY'
                // });
              }}
              className={
                props.active === 0 ? "link-item-active link-item" : "link-item"
              }
            >
              <Link to={base + "/play"}>PLAY</Link>
            </div>
            <div
              onClick={() => {
                // sa.track('LrwOverseasNavClick_W', {
                //   platform: 'PC',
                //   nav:'REVIEW'
                // });
              }}
              className={
                props.active === 1 ? "link-item-active link-item" : "link-item"
              }
            >
              <Link to={base + "/review"}>REVIEW</Link>
            </div>
            <div
              onClick={() => {
                // sa.track('LrwOverseasNavClick_W', {
                //   platform: 'PC',
                //   nav:'CONTACT'
                // });
              }}
              className={
                props.active === 2 ? "link-item-active link-item" : "link-item"
              }
            >
              <Link to={base + "/contact"}>CONTACT</Link>
            </div>

            <div
              onClick={() => {
                // sa.track('LrwOverseasNavClick_W', {
                //   platform: 'PC',
                //   nav:'INTRODUCTION'
                // });
              }}
              className={
                props.active === 3 ? "link-item-active link-item" : "link-item"
              }
            >
              <Link to={base + "/introduction"}>INTRODUCTION</Link>
            </div>
          </ul>

          <div className="lang">
            <span className="en">EN</span>
            <span
              className="ch"
              onClick={() => {
                window.location.href =
                  window.location.protocol + "//www.lurenwang.com";
              }}
            >
              CN
            </span>
          </div>
        </div>
      </div>

      <div>{props.children}</div>
    </div>
  );
}

export default Nav;
