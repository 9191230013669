import React from "react";
import {Container, Col, Row} from "react-grid-system";

import Logo from "./img/logo.svg";
import "./style.scss";

const FooterLiangle = () => {
    const on_link = (url) => {
        window.open(url);
    };
    return (
        <div className="footer-container2">
            <img src={Logo} alt="logo" className="logo"/>
            <div className="contact2">商务合作 邮箱： lrwbd@lurenwang.com</div>
            <div className="info2">
        <span
            className="pointer"
            onClick={() => on_link("https://assets.lurenwang.com/tv/static/home/img/icp/4_c228d46.jpg")}
        >
          亮了网 京ICP证070154号|
        </span>
                <span
                    className="pointer"
                    onClick={() => on_link("https://assets.lurenwang.com/tv/static/home/img/license/1_46d9600.jpg")}
                >
          
         信息网络传播视听业务节目许可证：0110460 |
        </span>
                <span
                    className="pointer"
                    onClick={() => on_link("https://assets.lurenwang.com/lrw/liangle_index/zhengahu.jpg")}
                >
        互联网药品信息服务资格证编号（京）-非经营性-2018-0276
        </span>
            </div>
        </div>
    );
};
export default function Footer() {
    const on_link = (url) => {
        window.open(url);
    };
    // const is_liangle = window.location.href.includes("3000");
    const is_liangle = window.location.href.includes("liangle.com");
    return (
        <>
            {is_liangle ? (
                FooterLiangle()
            ) : (
                <div className="footer-container2">
                    <img src={Logo} alt="logo" className="logo"/>
                    <div className="contact2">商务合作 邮箱： lrwbd@lurenwang.com</div>
                    <div className="info2">
                        路人王篮球赛事官方网站 ©All Rights Reserved |{" "}
                        <span
                            className="pointer"
                            onClick={() => on_link("https://assets.lurenwang.com/tv/static/home/img/license/1_46d9600.jpg")}
                        >
                
              信息网络传播视听业务节目许可证：0110460
            </span>
                        {" "}|{" "}
                        <span
                            className="pointer"
                            onClick={() => on_link("https://beian.miit.gov.cn/")}
                        >
              沪ICP备20011089号-7
            </span>
                        {" "}|{" "}<span className="pointer"
                                         onClick={() => on_link("https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010902003271")}>
                        <i className="icon_police"></i>沪公网安备31010902003271号
                </span>
                    </div>
                </div>
            )}
        </>
    );
}
