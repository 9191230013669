import axios from "axios";
import { env_is_sit, env_is_stg } from "./env";
import { message } from 'antd';

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Headers": "Content-Type",
  "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
  "Access-Control-Allow-Origin": "*",
  //'accept-encoding':'gzip',
  crossDomain: "true",
  // "withCredentials": true
};


if (localStorage.getItem('lrw-token')) {
  headers['Authorization'] = localStorage.getItem('lrw-token')
}

if (localStorage.getItem('lrw-user-id')) {
  headers['lrw-user-id'] = localStorage.getItem('lrw-user-id')
}


let baseUrl = "";
if (window.location.host.indexOf("local") > -1) {
  baseUrl = ``;
} else if (window.location.host.indexOf("sit") > -1) {
  baseUrl = `${window.location.protocol}//api-v1-sit.lurenwang.com`;
} else if (window.location.host.indexOf("stg") > -1) {
  baseUrl = `${window.location.protocol}//api-v1-stg.lurenwang.com`;
} else baseUrl = `${window.location.protocol}//api-v1.lurenwang.com`;

let baseJavaUrl = "";
if (window.location.host.indexOf("local") > -1) {
  baseJavaUrl = ``;
} else if (window.location.host.indexOf("sit") > -1) {
  baseJavaUrl = `${window.location.protocol}//api-sit.lurenwang.com`;
} else if (window.location.host.indexOf("stg") > -1) {
  baseJavaUrl = `${window.location.protocol}//api-stg.lurenwang.com`;
} else baseJavaUrl = `${window.location.protocol}//api.lurenwang.com`;

const service = axios.create({
  baseURL: baseUrl,
  headers,
  timeout: 5000,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    // if the custom code is not 20000 or 1, it is judged as an error.
    if (res.code !== 1) {
      console.log(res.msg);
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

const requestJava = axios.create({
  baseURL: baseJavaUrl,
  headers,
  timeout: 5000,
});

// request interceptor
requestJava.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
requestJava.interceptors.response.use(
  (response) => {
    const res = response.data;

    // if the custom code is not 20000 or 1, it is judged as an error.
    if (res.code !== 200) {
      if (res.code === 2002 || res.code === 2001 || res.code === 2005 || res.code === 2006) {
        localStorage.removeItem('lrw-token');
        localStorage.removeItem('lrw-user-id');
      }
      console.log(res.message);
      message.error(res.message || "Error")
      return Promise.reject(res.message || "Error");
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);


// 代理
let http_ = window.location.protocol;

const proxy_api = (api) => {
  if (window.location.href.includes("lurenwang.com"))
    return `${http_}//adc.lurenwang.com/srv/proxy?url=` + api;
  return `${http_}//adc.lrw2016.com/srv/proxy?url=` + api;
};

export const fetch_proxy = async (url) => {
  if (window.location.href.includes("localhost")) {
    return await fetch(proxy_api(url));
  }
  return await fetch(url);
};

/**
 * 直播页跳转url
 */
export const get_live_page_url = (game_id,roomId) => {
  if (env_is_sit()) {
    return `${http_}//www-sit.lurenwang.com/#/live?game_id=${game_id}&room_id=${roomId}`; //11075
  } else if (env_is_stg()) {
    return `${http_}//www-stg.lurenwang.com/#/live?game_id=${game_id}&room_id=${roomId}`; //11075
  }
  //生产环境
  return `${http_}//www.lurenwang.com/#/live?game_id=${game_id}&room_id=10975`; //11075
};



export {
  service, requestJava
};

