import React, {useState, useEffect} from 'react';
import './index.scss';
import playIcon from './icon/play.png'


const Video = (props) => {
  const [mask, setMask] = useState(false);
  const {video} = props;

  function handleClick() {
    if (video.url && video.url.indexOf('http') > -1) {
      window.open(video.url);
    } else {
      window.open('http://' + video.url);
    }
  }

  return (
    <div className='video-block2'>
      <div
        className='video-poster'
        onClick={handleClick}
      >
        <div className="mask"/>
        <img src={video.img} alt=""/>
        <img src={playIcon} className='play-icon' alt=""/>
      </div>
      {
        mask &&
        <div className='mask-container'>
          <div
            className='video-mask'
            onClick={() => setMask(false)}
          />
          <div id={props.id} className='video-player'/>
        </div>
      }
      <div className='video-title' onClick={handleClick}>
        {video.title}
      </div>
    </div>
  );
};


export default Video;