import React, { useEffect, useState } from "react";
import { Col, Container, Row, setConfiguration } from "react-grid-system";
import { Link } from "react-router-dom";
import { getGameList3 } from "../../api/pc";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { game_list } from "../../data/game_list";
import { dateFormat, format_date, getQuery, useWillSize } from "../../utils";
import { env_is_sit , env_is_stg} from "../../utils/env";
import { get_live_page_url } from "../../utils/request";
import crown from "./img/crown.png";
import icon_live from "./img/icon_live.png";
import play from "./img/play.png";
import play_yellow from "./img/play_yellow.png";
import { getNextMonth, getPreMonth } from "./month";
import "./style.scss";
import "./style2.scss";

const getDataTime = (data, game_status) => {
  const params = dateFormat(data);
  //未开始的比赛 显示具体时间。比赛结束 显示年和月
  const day = params.today
    ? `今日 ${params.day.split(" ")[1].substr(0, 5)}`
    : params.tomorrow
    ? `明日 ${params.day.split(" ")[1].substr(0, 5)}`
    : params.yesterday
    ? "昨日"
    : params.year
    ? `${params.year}年${params.month}月${params.day.split(" ")[0]}日`
    : game_status < 5
    ? `${params.month}月${params.day.split(" ")[0]}日 ${params.day
        .split(" ")[1]
        .slice(0, -3)}`
    : `${params.month}月${params.day.split(" ")[0]}日`;
  return day;
};
const debug = false;
const card_perc = 0.7;
const GameComing = ({ title }) => {
  return (
    <Row justify="end">
      <Col sm={1}>
        <div className="line_01"></div>
      </Col>
      <Col>
        <div className="yellow-title">{title}</div>
      </Col>
    </Row>
  );
};
const GameLive = ({ game_id }) => {
  const on_live = () => {
    window.open(get_live_page_url(game_id));
  };
  return (
    <div>
      <Row justify="end" style={{ marginLeft: 18 }}>
        <Col sm={1}>
          <div className="line_01"></div>
        </Col>
        <Col sm={1}>
          {/* <div className="flexbox"> */}
          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
            <img
              src={play_yellow}
              alt="play_yellow"
              className="video-img"
              style={{ cursor: "pointer" }}
              onClick={on_live}
            />
          </div>
          {/* </div> */}
        </Col>
        <Col sm={7}>
          <div style={{ marginTop: -8 }}>
            <div className="yellow-title pointer" onClick={on_live}>
              视频直播
            </div>
          </div>
        </Col>
        <Col>
          {/* <div className="flexbox"> */}
          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
            <img src={icon_live} alt="icon_live pointer" onClick={on_live} />
          </div>
          {/* </div> */}
        </Col>
        <Col sm={7}>
          <div style={{ marginTop: -8, marginLeft: -20 }}>
            <div className="yellow-title pointer" onClick={on_live}>
              进行中
            </div>
          </div>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </div>
  );
};
const GameEnd = ({ champion, vid, game_id }) => {
  const on_video = () => {
    if (env_is_sit()) {
      window.open(
        `https://www-sit.lurenwang.com/#/video?vid=${vid}&game_id=${game_id}`
      );
    }
    else if (env_is_stg()) {
      window.open(
        `https://www-stg.lurenwang.com/#/video?vid=${vid}&game_id=${game_id}`
      );
    }
    else {
      window.open(
        `https://www.lurenwang.com/#/video?vid=${vid}&game_id=${game_id}`
      );
    }
  };
  return (
    <>
      {/* <Col sm={14}></Col> */}
      {vid ? (
        <>
          <Row justify="end" debug={debug}>
            {champion ? (
              <>
                <div className="line_01"></div>
                <Col sm={1}>
                  <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <img src={crown} alt="crown" className="icon2" />
                  </div>
                </Col>
                <Col>
                  <div className="gray-title">冠军：{champion}</div>
                </Col>
                <Col sm={1}>
                  <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                    <img
                      src={play}
                      alt="play"
                      className="icon2 pointer"
                      onClick={on_video}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="gray-title pointer" onClick={on_video}>
                    视频回放
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Row justify="end" debug={debug} style={{ marginRight: 8 }}>
                  <Col sm={1}>
                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                      <img
                        src={play}
                        alt="play"
                        className="icon2 pointer"
                        onClick={on_video}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="gray-title pointer" onClick={on_video}>
                      视频回放
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </>
      ) : (
        <Row justify="end">
          <Col sm={1}>
            <div className="line_01"></div>
          </Col>
          <Col>
            <div className="gray-title">已结束</div>
          </Col>
        </Row>
      )}
    </>
  );
  return (
    <div className="game-end">
      {vid ? (
        <Row justify="start">
          {champion && champion.name ? (
            <>
              <div className="line_01"></div>
              <Col sm={1}>
                <img src={crown} alt="crown" />
              </Col>
              <Col sm={10}>
                <div className="title">冠军：{champion.name}</div>
              </Col>
              <Col sm={1}>
                <img
                  src={play}
                  alt="play"
                  className="video-img"
                  onClick={on_video}
                />
              </Col>
              <Col sm={9}>
                <div className="video-title" onClick={on_video}>
                  视频回放
                </div>
              </Col>
            </>
          ) : (
            <>
              {/* <Row justify="start" debug> */}
              <Col sm={12}></Col>

              <div className="line_01"></div>
              <Col sm={1}>
                <img
                  src={play}
                  alt="play"
                  className="video-img"
                  onClick={on_video}
                />
              </Col>
              <Col sm={9}>
                <div className="video-title" onClick={on_video}>
                  视频回放
                </div>
              </Col>
              {/* </Row> */}
            </>
          )}
        </Row>
      ) : (
        // <Row justify="start">
        <>
          {/* <Col sm={14}></Col> */}
          <Col>
            <div className="line_01"></div>
            <div className="title">已结束</div>
          </Col>
        </>

        // </Row>
      )}
    </div>
  );
};
const GameCard = ({ game: item }) => {
  let champion = "";
  if (item.championPlayer && item.championPlayer.name) {
    champion = item.championPlayer.name || "";
  }
  if (item.matchState === 'has_ended') {
    console.log("game card :>> ", item);
    // console.log("game card :>> ",  item.champion.name);
  }
  // const day = getDataTime(item.startTime, item.matchState);
  let game_start_hour = "19:30";
  try {
    let a = item["startTime"].split(" ")[1].split(":");
    game_start_hour = a[0] + ":" + a[1];
  } catch (e) {}
  const win_size = useWillSize();

  if (String(item["matchId"]) === "1390") {
    console.log("比赛有回放 :>> ", item);
  }

  return (
    <div
      className="game-card"
      style={{
        position: "relative",
        // marginLeft: win_size.width * (1 - card_perc) * 0.5,
        // width: win_size.width * card_perc,
      }}
    >
      <div className="area-top">{item["startTime"].split(" ")[0]}</div>
      <div className="area-card2">
        <Container>
          <Row justify="start">
            <Col sm={1}>
              <div className="area-title">{game_start_hour}</div>
            </Col>

            <Col sm={22}>
              <Row justify="between">
                <Col sm={12}>
                  <div className="line_01"></div>
                  <div className="area-title">{item.matchName}</div>
                </Col>
                <Col sm={12}>
                  <Row justify="end" debug={debug}>
                    {item["matchState"] === 'has_ended' ? (
                      <GameEnd
                        {...{
                          champion,
                          vid: item.videoInfo?.vid || '',
                          game_id: item.matchId,
                        }}
                      ></GameEnd>
                    ) : item["matchState"] === 'to_be_start' ? (
                      <GameComing {...{ title: "即将开赛" }}></GameComing>
                    ) : item["matchState"] === 'in_the_match' ? (
                      <GameLive {...{ game_id: item["matchId"] }}></GameLive>
                    ) : (
                      <GameComing {...{ title: "未开赛" }}></GameComing>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
const GameTop = ({ pre_month, next_month }) => {
  const win_size = useWillSize();
  return (
    <div
      className="area-top2"
      style={{
        position: "relative",
      }}
    >
      <div className="title">
      路人王城市赛赛程
        {/* <span style={{ marginLeft: "30px" }}>
          {pre_month} - {next_month}
        </span> */}
      </div>
      <div className="right" style={{ right: 20 }}>
        <div className="btn-more">
          <Link to="/videolist" style={{ textDecoration: "none" }}>
            更多视频
          </Link>
        </div>
      </div>
    </div>
  );
};
export default function GameList({ history }) {
  setConfiguration({ defaultScreenClass: "sm", gridColumns: 24 });
  //
  const [state, set_state] = useState({
    game_arr: [],
    pre_month: "",
    next_month: "",
  });

  const get_game_list = async () => {
    let res = await getGameList3();
    console.log("原始数据：=>", res);
    if (getQuery("test") === "1") res = game_list;
    let game_arr = res["result"];
    let today_str = format_date(new Date(), "yyyy-MM-dd");
    let pre_month = new Date(getPreMonth(today_str));
    let next_month = new Date(getNextMonth(today_str));
    let pre_month_str = format_date(pre_month, "MM月dd");
    let next_month_str = format_date(next_month, "MM月dd");
    console.log("比赛列表 :>> ", game_arr);
    set_state({
      game_arr: game_arr,
      pre_month: pre_month_str,
      next_month: next_month_str,
    });
  };
  useEffect(() => {
    (async () => {
      get_game_list();
    })();
  }, []);
  return (
    <div>
      <Header location={history.location} />
      <div className="game-page">
        <GameTop
          {...{ pre_month: state.pre_month, next_month: state.next_month }}
        />
        {state.game_arr.map((game, i) => (
          <GameCard key={i} {...{ game }} />
        ))}
      </div>
      <Footer />
    </div>
  );
}
