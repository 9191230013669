import { env_is_sit, env_is_sit_newlive , env_is_stg} from "../../utils/env";

export const nav_video_page = (item) => {
  let game_id = item.matchId ? item.matchId : "";
  let game_param = Number(game_id) > 0 ? `&game_id=${game_id}` : "";
  if (env_is_sit_newlive() || env_is_sit()) {
    window.open(
      `https://www-sit.lurenwang.com/#/video?vid=${item.vid}${game_param}`
    );
  }
  else if (env_is_stg()) {
    window.open(
      `https://www-stg.lurenwang.com/#/video?vid=${item.vid}${game_param}`
    );
  }
  else {
    window.open(
      `https://www.lurenwang.com/#/video?vid=${item.vid}${game_param}`
    );
  }
};
