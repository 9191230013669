export const videolist = {
  code: 1,
  msg: "操作成功",
  data: {
    pages: 239,
    list: [
      {
        vid: "lnv1pvw0kwz",
        title: "路人王2021赛季城市冠军赛-成都站",
        cover:
          "http://w2.hoopchina.com.cn/e8/f3/b1/e8f3b15c45b3f868620ad22ea376afe5001.jpg@300h_534w_1e_1c_60q",
        tag: ["周锐", "谢亚洲", "范成林"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "3139",
        create_time: "07/13",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/lnv1pvw0kwz",
      },
      {
        vid: "njm3nk1zwo9",
        title: "路人王2021赛季城市冠军赛-上海站",
        cover:
          "http://w4.hoopchina.com.cn/7f/7d/f1/7f7df1995e18846eb0370c341df0534b001.jpg@300h_534w_1e_1c_60q",
        tag: ["孟亚东", "白晶", "林灿生"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "4837",
        create_time: "07/09",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/njm3nk1zwo9",
      },
      {
        vid: "8jy7oo1zg1l",
        title: "路人王2021赛季城市冠军赛-济南站",
        cover:
          "http://w4.hoopchina.com.cn/7f/7d/f1/7f7df1995e18846eb0370c341df0534b001.jpg@300h_534w_1e_1c_60q",
        tag: ["白晶", "周锐", "唐日辉"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "9056",
        create_time: "07/06",
        type: 2,
        score: "8.4",
        abs_url: "http://www.liangle.com/video/8jy7oo1zg1l",
      },
      {
        vid: "34vmz72n61l",
        title: "路人王2021赛季城市冠军赛-天津站",
        cover:
          "http://w1.hoopchina.com.cn/b5/80/b3/b580b3a6c4d18ebde843e2739f55ea44001.jpg@300h_534w_1e_1c_60q",
        tag: ["戚越鹏", "孟亚东", "徐昊"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "4961",
        create_time: "07/02",
        type: 2,
        score: "6.0",
        abs_url: "http://www.liangle.com/video/34vmz72n61l",
      },
      {
        vid: "y1g0gk1nq7o",
        title: "路人王2021赛季城市冠军赛-厦门站",
        cover:
          "http://w3.hoopchina.com.cn/96/89/be/9689bef2ece3e0c67d71dc2d805710c6001.jpg@300h_534w_1e_1c_60q",
        tag: ["孟亚东", "周锐", "宋流轩"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "6411",
        create_time: "06/29",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/y1g0gk1nq7o",
      },
      {
        vid: "4vyg86gvy0e",
        title: "路人王2021赛季城市冠军赛-杭州站",
        cover:
          "http://w2.hoopchina.com.cn/13/8d/04/138d04e8cb1d9364021b1260e65a2ed2001.jpg@300h_534w_1e_1c_60q",
        tag: ["白晶", "龚志祥", "孟亚东"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "7336",
        create_time: "06/23",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/4vyg86gvy0e",
      },
      {
        vid: "y1g410oj7zo",
        title: "路人王2021赛季城市冠军赛-太原站",
        cover:
          "http://w3.hoopchina.com.cn/5c/cf/64/5ccf6492c69e6f4fe5d957cff5c634ed001.jpg@300h_534w_1e_1c_60q",
        tag: ["孟亚东", "汪宇扬", "王浩宇"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "6820",
        create_time: "06/19",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/y1g410oj7zo",
      },
      {
        vid: "24go38ex0xz",
        title: "路人王2021赛季城市冠军赛-南京站",
        cover:
          "http://w3.hoopchina.com.cn/b0/ff/ae/b0ffae84130174f5043e69944eee0aee001.jpg@300h_534w_1e_1c_60q",
        tag: ["白晶", "马克", "孟亚东"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "9951",
        create_time: "06/10",
        type: 2,
        score: "9.0",
        abs_url: "http://www.liangle.com/video/24go38ex0xz",
      },
      {
        vid: "34vo6ow72k4",
        title: "路人王2021赛季城市冠军赛-武汉站",
        cover:
          "http://w3.hoopchina.com.cn/b0/ff/ae/b0ffae84130174f5043e69944eee0aee001.jpg@300h_534w_1e_1c_60q",
        tag: ["周锐", "郭凌川", "唐昊旻"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "8214",
        create_time: "06/09",
        type: 2,
        score: "8.4",
        abs_url: "http://www.liangle.com/video/34vo6ow72k4",
      },
      {
        vid: "x8p11n0p4gn",
        title: "路人王2021赛季城市冠军赛-济南站",
        cover:
          "http://w3.hoopchina.com.cn/ae/19/a1/ae19a14c39e60d83648525019e957b42001.jpg@300h_534w_1e_1c_60q",
        tag: ["白晶", "龚志祥", "董泞嘉"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "9551",
        create_time: "06/04",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/x8p11n0p4gn",
      },
      {
        vid: "34v16l13q47",
        title: "路人王2021赛季城市冠军赛-深圳站",
        cover:
          "http://w1.hoopchina.com.cn/6b/f4/8f/6bf48fe2ee8134a74214416a753020c3001.jpg@300h_534w_1e_1c_60q",
        tag: ["柳杨杰", "古展腾", "白晶"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "10523",
        create_time: "05/24",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/34v16l13q47",
      },
      {
        vid: "24gnj7w2n77",
        title: "路人王2021赛季城市冠军赛-成都站",
        cover:
          "http://w1.hoopchina.com.cn/39/c3/92/39c3923b1294f16975f5333d618ba6ab001.jpg@300h_534w_1e_1c_60q",
        tag: ["白晶", "刘国秋", "邹旭辉"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "8938",
        create_time: "05/21",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/24gnj7w2n77",
      },
      {
        vid: "enwvl1063qg",
        title: "路人王2021赛季城市冠军赛-北京站",
        cover:
          "http://w3.hoopchina.com.cn/31/54/01/3154017a1b1fa79638e506d93a42880e001.jpg@300h_534w_1e_1c_60q",
        tag: ["戚越鹏", "龚志祥", "张梓杨"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "4878",
        create_time: "05/20",
        type: 2,
        score: "2.0",
        abs_url: "http://www.liangle.com/video/enwvl1063qg",
      },
      {
        vid: "gnln0eme11m",
        title: "路人王2021赛季城市冠军赛-西安站",
        cover:
          "http://w4.hoopchina.com.cn/50/97/8c/50978ce46f0d7d505ffbc0efbd7bc5d0001.jpg@300h_534w_1e_1c_60q",
        tag: ["柳杨杰", "范旭飞", "王元"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "33621",
        create_time: "05/14",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/gnln0eme11m",
      },
      {
        vid: "wljgjzxgvmn",
        title: "路人王2021赛季城市冠军赛正赛-广州站",
        cover:
          "http://i11.hoopchina.com.cn/all-backend/16208021733634889000.jpg@300h_534w_1e_1c_60q",
        tag: [],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "4718",
        create_time: "05/08",
        type: 2,
        score: "6.0",
        abs_url: "http://www.liangle.com/video/wljgjzxgvmn",
      },
      {
        vid: "pq6omovgplj",
        title: "路人王2021赛季城市冠军赛-重庆站",
        cover:
          "http://i11.hoopchina.com.cn/all-backend/16204816351192756207.jpg@300h_534w_1e_1c_60q",
        tag: ["白晶", "周锐", "韦鸽子"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "34724",
        create_time: "05/07",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/pq6omovgplj",
      },
      {
        vid: "opnn6qo9e7z",
        title: "路人王2021赛季城市冠军赛-沈阳站",
        cover:
          "http://i11.hoopchina.com.cn/all-backend/16196178379201133359.jpg@300h_534w_1e_1c_60q",
        tag: ["周锐", "刘梦良", "仲嘉卿"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "11637",
        create_time: "04/28",
        type: 2,
        score: 10,
        abs_url: "http://www.liangle.com/video/opnn6qo9e7z",
      },
      {
        vid: "ql66711y8z4",
        title: "路人王2021赛季城市冠军赛-天津站",
        cover:
          "http://w2.hoopchina.com.cn/75/ca/77/75ca77aabf53bf2a4cb34ed4aa153726001.jpg@300h_534w_1e_1c_60q",
        tag: ["柳杨杰", "孟亚东", "高剑"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "18683",
        create_time: "04/27",
        type: 2,
        score: "5.8",
        abs_url: "http://www.liangle.com/video/ql66711y8z4",
      },
      {
        vid: "4vywk14xy1y",
        title: "光环购物公园×路人王城市定制赛",
        cover:
          "http://huputv-vod-in.oss-cn-hangzhou.aliyuncs.com/lrw/20210426/42e4450437d33702c15acf5080407f4a1619371776716.mp4?OSSAccessKeyId=LTAIMt1RwBGqGLCw&Expires=1619375515&Signature=pv%2F5kekRzJFs7mIxqOAIlJfergE%3D&x-oss-process=video%2Fsnapshot%2Ct_4000%2Cf_jpg%",
        tag: [],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "4096",
        create_time: "04/24",
        type: 2,
        score: 0,
        abs_url: "http://www.liangle.com/video/4vywk14xy1y",
      },
      {
        vid: "m7n1n4nooo2",
        title: "路人王2021赛季城市冠军赛-杭州站",
        cover:
          "http://w3.hoopchina.com.cn/13/8d/04/138d04e8cb1d9364021b1260e65a2ed2001.jpg@300h_534w_1e_1c_60q",
        tag: ["周锐", "柳杨杰", "白晶"],
        tid: "0",
        recommend: "0",
        recommend_tag: "",
        play_num: "7680",
        create_time: "04/22",
        type: 2,
        score: "8.7",
        abs_url: "http://www.liangle.com/video/m7n1n4nooo2",
      },
    ],
  },
};
