// src/router/index.js
import PCHome from "../views/Home";
import NewList from "../views/NewsList";
import About from "../views/About";
import News from "../views/News";
import VideoList from "../views/VideoList";
import LiveRoom from "../views/LiveRoom";
import GameList from "../views/GameList";
import VideoRoom from "./../views/VideoRoom";
import Signtip from "../views/Signtip";
import Rank from "./../views/Rank/index";
import SignPlayers from "../views/SignPlayers";
import HWHome from '../views/HoopScomesHome/pages/home';
import HWIntro from '../views/HoopScomesHome/pages/introduction';
import HWContact from '../views/HoopScomesHome/pages/contact';
import HWReview from '../views/HoopScomesHome/pages/review';
import HWPlay from '../views/HoopScomesHome/pages/detail';
// const HWHome = require('../views/HoopScomesHome/pages/home')
// const HWIntro = require('../views/HoopScomesHome/pages/introduction')
// const HWContact = require('../views/HoopScomesHome/pages/contact')
// const HWReview = require('../views/HoopScomesHome/pages/contact')
// const HWPlay = require('../views/HoopScomesHome/pages/detail')
let routes = [
  {
    path: "/",
    exact: true,
    component: PCHome,
  },
  {
    path: "/newslist",
    exact: true,
    component: NewList,
  },
  {
    path: "/videolist",
    exact: true,
    component: VideoList,
  },
  {
    path: "/video",
    exact: true,
    component: VideoRoom,
  },
  {
    path: "/signtip",
    exact: true,
    component: Signtip,
  },
  {
    path: "/signplayers",
    exact: true,
    component: SignPlayers,
  },
  {
    path: "/rank",
    exact: true,
    component: Rank,
  },
  {
    path: "/live",
    exact: true,
    component: LiveRoom,
  },
  {
    path: "/gamelist",
    exact: true,
    component: GameList,
  },
  {
    path: "/videolist",
    exact:true,
    component: VideoList
  },
  {
    path: "/about",
    exact: true,
    component: About,
  },
  {
    path: "/news/:nid",
    exact: true,
    component: News,
  },
  {
    path: "/hoopscomeshome/",
    exact: true,
    component: HWHome,
  },
  {
    path: "/hoopscomeshome/introduction",
    exact: true,
    component: HWIntro,
  },
  {
    path: "/hoopscomeshome/contact",
    exact: true,
    component: HWContact,
  },
  {
    path: "/hoopscomeshome/review",
    exact: true,
    component: HWReview,
  },
  {
    path: "/hoopscomeshome/play",
    exact: true,
    component: HWPlay,
  },
];

if (window.location.href.includes("newlive.")) {
  /**
   * 当实用二级域名访问时候 根路径为 直播页 方便解析
   */
  routes = [
    {
      path: "/live",
      exact: true,
      component: LiveRoom,
    },
    {
      path: "/video",
      exact: true,
      component: VideoRoom,
    },
  ];
}

export default routes;
