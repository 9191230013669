import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './views/App';
import './utils/flexible';
import './utils/rem'
import { isMobile } from './utils/index'
import 'antd/dist/antd.css';



if (isMobile()) {
  console.log('enter mobile');
  let url = ''
  if (window.location.host.indexOf('local') > -1) {
    url = ''
  } else if (window.location.host.indexOf('sit') > -1) {
    url = `${window.location.protocol}//m-sit.lurenwang.com`
  } else if (window.location.host.indexOf('stg') > -1) {
    url = `${window.location.protocol}//m-stg.lurenwang.com`
  } else {
    url = `${window.location.protocol}//m.lurenwang.com`
  }
  if (url) {
    window.location.href = url;
  }
} else {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
}

