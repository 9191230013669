import React, {useState, useEffect, createRef} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./style.scss";
import Search from "./img/search.svg";
import crown from "./img/crown.png";
import {getVideoList, getSearchVideoList} from "../../api/pc";
import {Container, Row, Col} from "react-grid-system";

import {setConfiguration} from "react-grid-system";
import {nav_video_page} from "./nav-video-page";

const VideoItem = (prop) => {
    const on_video = (vid) => {
        nav_video_page(prop);
    };

    return (
        <div className="video-item" onClick={() => on_video(prop.vid)}>
            <div className="cover-container">
                <img className="cover" src={prop.cover}/>
                <img className="img-play" src="https://w2.lurenwang.com/upload/source/9c9d4f43e41c4e7d8c2bb4ab9ad4c404.png" alt="img-play"/>
            </div>
            <div className="title">{prop.title}</div>
            <div className="info">
                <div className="left">
                    {prop.tags && (
                        <>
                            <img src={crown} alt="crown"/>
                            <div
                                className="champion">冠军: {prop.tags.includes(',') ? prop.tags.split(',')[0] : prop.tags}</div>
                            {" "}
                        </>
                    )}
                </div>
                <div className="right">
                    <div className="date">
                        <span>{prop.createDate.split(' ')[0]}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

//
const SearchToolBar = (props) => {
    const on_key_up = (e) => {
        if (e.keyCode === 13) {
            search();
        }
    };
    const on_search = () => {
        search();
    };
    const search = () => {
        let v = keywordref.current.value;
        props.on_search(v);
    };
    const keywordref = createRef();
    return (
        <>
            <Container>
                <Row>
                    <Col sm={20}>
                        <div className="search-tool-bar">
                            <div id="DIV_1">
                                <input
                                    placeholder="请输入关键词进行搜索"
                                    onKeyUp={on_key_up}
                                    ref={keywordref}
                                    id="INPUT_2"
                                />
                                <div id="DIV_3" onClick={on_search}>
                  <span id="SPAN_4">
                    <img
                        className="search-icon"
                        src={Search}
                        alt="Search Logo"
                    />
                    搜索
                  </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
            </Container>
        </>
    );
};
const PageNav = (props) => {
    const [page, set_page] = useState(1);
    const page_next = (dt) => {
        set_page((page) => {
            let res = props.page + dt;
            if (res < 1) res = 1;
            props.on_page(res);
            return res;
        });
    };
    return (
        <>
            {props.has_more && (
                <div className="footer-more">
                    <div className="more-btn" onClick={() => page_next(1)}>
                        加载更多
                    </div>
                </div>
            )}
        </>
    );
};

export default function VideoList({history}) {
    setConfiguration({
        defaultScreenClass: "sm",
        gutterWidth: 50,
        gridColumns: 24,
    });
    const [page_num, set_page_num] = useState(1);
    const [page, set_page] = useState(1);
    const [item_arr, set_item_arr] = useState([]);
    const [search_title, set_search_title] = useState("");
    const [has_more, set_has_more] = useState(true);
    const fetch_page = async (cur_page) => {
        console.log("cur_page:", cur_page);
        set_page(cur_page);
        let result = await getVideoList({current: cur_page, size: 20, search: search_title || ""});
        set_page_num(result.result.total);
        set_item_arr((item_arr) => item_arr.concat(result.result.data));
    };
    const on_page = async (page1) => {
        await fetch_page(page1);
    };
    const on_search = (st) => {
        set_item_arr([]);
        set_page(1);
        if (search_title === st) {
            fetch_page(1);
        } else {
            set_search_title(st);
        }
    };

    useEffect(() => {
        (async () => {
            fetch_page(1);
        })();
    }, [search_title]);

    useEffect(() => {
        if (page_num > page * 20) {
            set_has_more(true);
        } else {
            set_has_more(false);
        }
    }, [page_num]);
    return (
        <div className="video-list2">
            <Header location={history.location}/>
            <div className="video-page">
                <SearchToolBar {...{on_search}}></SearchToolBar>
                <Container>
                    <Row>
                        {item_arr.map((it) => (
                            <Col sm={6}>
                                <VideoItem {...it} key={it.vid}></VideoItem>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <PageNav {...{page_num, page, on_page, has_more}} />
            </div>
            <Footer/>
        </div>
    );
}
