import React, {Component,} from 'react';
import Nav from '../../component/navigation';
import {withRouter} from "react-router-dom";
import CopyRight from '../../component/copyRIght';
import Title from '../../component/title';

import './index.scss';
import works from './img/howitworks.png'
import rule1 from './img/rule1.png'
import RULESOFHOOPSCOMESHOME from './img/RULESOFHOOPSCOMESHOME.png'
import rule2 from './img/rule2.png'
import rule3 from './img/rule3.png'
class Index extends Component {
  /**
   * [render description]
   * @return {[type]} [description]
   */


  componentDidMount() {
  }

  render() {

    return (
      <div className="detailPage">
        <Nav active={0}/>
        <main>
          {/* <Title title={"HOW IT WORKS"} /> */}
          <img src={works} style={{height: '64px', width: 'auto'}}/>
          <div className="des">
            Hoops comes home is an 1vs1 basketball tournament held by The King of Underdogs.We are going to more cities and give grassroots players a chance to show themselves.
          </div>

          <img src={rule1} style={{margin: '54px 0 204px'}} />
          <img src={RULESOFHOOPSCOMESHOME} style={{height: '64px', width: 'auto'}}/>
          <img src={rule2} style={{margin: '102px 0'}}/>
          <img src={rule3} />
        </main>
        <CopyRight active={0}/>
      </div>
    );
  }
}


export default Index;