import H5Iframe from "../../components/h5-iframe";
import { env_is_sit , env_is_stg} from "../../utils/env";

export default function SignPlayers({ history }) {
  let url = env_is_sit()
  ? "https://h5-sit.lurenwang.com/h5/signplayers"
  : env_is_stg()
  ? "https://h5-stg.lurenwang.com/h5/signplayers"
  : "https://h5.lurenwang.com/h5/signplayers";
  let height = "3080px";
  return <H5Iframe {...{ history, url, height,scroll:'auto' }}></H5Iframe>;
}
