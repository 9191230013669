import React, {Component,} from 'react';
import Nav from '../../component/navigation'
import {withRouter} from "react-router-dom";
import CopyRight from '../../component/copyRIght'

import Title from '../../component/title';

import './index.scss';
import hoops from './img/hoops.png'
import shldon from './img/sheldon bailey.png'
class Index extends Component {
  /**
   * [render description]
   * @return {[type]} [description]
   */


  componentDidMount() {
  }

  render() {

    return (
      <div className="reviewPage">
        <Nav active={1}/>
        <main>
          <img src={hoops} style={{height: '64px', width: 'auto', marginBottom: '102px'}}/>
          <div className="des">
            Sun, beach and beautiful court, Hoops comes home world series first stop was held in Los Angeles Venice beach stadium. Hundreds of local talent competed, and 24 of our players competed according to The King of Underdogs rule.Finally the former James stand-in—Sheldon Bailey won the championship.
          </div>
          <img src={shldon} />

          <div className="des">
            Sheldon Bailey was born at the U.S. army base in Germany, grew up in fayetteville, north Carolina, and grew up in SAN Antonio and fort Knox. The unsettled childhood has cultivated his cheerful character. He began to perform in theaters in the eighth grade, and throughout middle school he developed a talent for both acting and sports.
          </div>

          <div className="des">
            During high school, Bailey attended a basketball high school where she had been followed by amare stoudemire and jarrett jack, who had been his roommate. In college, Bailey served as President of the school's black student league, but continued to play basketball.
          </div>
          <div className="des">
            After graduating from college, Bailey chose basketball as his major career and played in ABA league. While working for ABA, he auditioned for the movie "the semi-pro," which he used as an opportunity to pursue an acting career in Los Angeles, citing injuries and the inability to reunite with his family.
          </div>
          <div className="des">
            Bailey has appeared in several skits and commercials in 2013, appearing in numerous commercials as a stand-in for Lebron James. Since then he also in the us TV series (Shameless) has played.
          </div>
          <div className="des">
            We are glad that we had two popular celebrities in this event, the bone collector and kuzma. And the popular hip-hop group Shoreline Mafia as a DJ. The atmosphere is lively. Sheldon Bailey won the championship and kuzma awarded the winner.
          </div>
        </main>

        <CopyRight active={1}/>
      </div>
    );
  }
}


export default Index;