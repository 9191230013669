export const env_is_sit = () => {
  return window.location.href.includes("www-sit.lurenwang.com");
};

export const env_is_sit_newlive = () => {
  return window.location.href.includes("www-sit.lurenwang.com");
};

export const env_is_localhost = () => {
  return window.location.href.includes("localhost");
};

export const env_is_stg = () => {
  return window.location.href.includes("www-stg.lurenwang.com");
};

export const env_is_prd = () => {
  return window.location.href.includes("www.lurenwang.com");
};

export const env_is_on_liangle = () => {
  return window.location.href.includes("liangle.com");
  
}