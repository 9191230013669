import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"; 

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import { game, notice } from './img/imgs'

import { getAllNews } from '../../api/pc'
import './style.scss'

export default function NewsList(props) {
  console.log(props, 'props');
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasEnd, setEnd] = useState(false);

  const goToNewsDetail = (id) => {
    props.history.push(`/news/${id}`)
  }

  const fetchData = async (params) => {
    if (hasEnd) return
    const result =  await getAllNews(params)
    setList([...list, ...result.data])
    setEnd(result.data.length < 16)
    setPage(page + 1)
  }

  useEffect(() => {
    fetchData({
      page: page,
      limit: 20
    });
  }, []);
  return (
    <div className="news-list-container">
      <Header />
        <div className="list-container">
          <div className="indexs">
            <Link to="/">首页</Link>><Link to="/newslist">资讯</Link>
          </div>
          <div className="list">
            <div className="header">新闻资讯</div>
            {
              list.map(n => (
                <div className="news" key={n.id} onClick={() => goToNewsDetail(n.id)}>
                  <div className="type">【{n.categoryTitle}】</div>
                  <div className="title">{n.contentTitle}</div>
                  { +n.contentSort === 999 ? <div className="top">置顶</div> : null }
                  <div className="time">{n.contentDatetime}</div>
                </div>
              ))
            }
            
            {/* <div className="news">
              <img src={notice} alt="notice" className="notice-icon"/>
            </div>
            <div className="news">
              <img src={game} alt="game" className="game-icon"/>
            </div> */}
            { !hasEnd ? <div className="footer">
              <div className="more-btn" onClick={() => fetchData({ page: page, limit: 20})}>加载更多</div>
            </div> : null }
          </div>
        </div>
      <Footer/>
    </div>
  )
}
