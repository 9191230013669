import React from 'react';
// import Swiper from 'react-id-swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './index.scss'


import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);

const FractionPagination = (props) => {
  // const params = {
  //   autoplay: {
  //     delay:10000,
  //   },
  //   loop:true,

  //   pagination: {
  //     // el: '.swiper-pagination',
  //     // clickable: true,
  //     // dynamicBullets: true
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   }
  // };

  return (

    <Swiper
        slidesPerView={1}
        loop={true}
        navigation
        autoplay={{
          "delay": 10000,
          "disableOnInteraction": false
        }}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log('slide change')}
      >
        { 
          props.swiperList.map((item, idx) => (
            <SwiperSlide  key={idx} >
              <div className="img-tab">
                <img src={item.img.split('?')[0]} alt="" style={{width:'100%'}}/>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    // <Swiper {...params}>
    //   {
    //     props.swiperList && props.swiperList.map((item, index) => {
    //       return <div
    //         className='swiper-item'
    //         key={index}
    //         onClick={()=>{
    //           // sa.track('LrwOverseasBannerClick_W', {
    //           //   platform: 'PC',
    //           //   link:item.url
    //           // });
    //           if(item.url && item.url.indexOf('http')>-1){
    //             window.open(item.url);
    //           }else{
    //             window.open('https://'+item.url);
    //           }
    //         }}
    //       >
    //         <img src={item.img.split('?')[0]}/>
    //       </div>;
    //     })
    //   }
    // </Swiper>
  );
};


export default FractionPagination;