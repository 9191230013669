export const player_list = {
  code: 1,
  msg: "操作成功",
  data: {
    A: [
      {
        group_match_num: "1",
        player: {
          id: "52",
          pid: "nx286",
          puid: "20115310",
          name: "韦瑞恒11",
          nickname: "雾里小丑",
          header:
            "http://w3.hoopchina.com.cn/0c/8d/c8/0c8dc864267d2e33ae4c591a7413d19f002.png",
          height: "185",
          weight: "75",
          group: "2",
          level: "0",
          card_value: "341221199410010475",
          birthday: "1994-10-01",
          sex: "男",
          location: "155387",
          mobile: "18844123900",
          run_height: "320",
          school: "哈哈哈哈大学",
          job: "是吗",
          entry_experience: "那天",
          entry_reflections: "用最放松的心态迎战各路JR",
          total_income: "94",
          lightness: "940",
          tag: "",
          total_champion: "10",
          total_game: "141",
          total_win: "97",
          rate: 68,
          player_num: "1",
          group_match_num: "1",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "上海",
          run_height_desc: "320",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: "1",
          age: 26,
          constellation: "天秤座",
          title: "哈萨克海陆空三大合肥克拉还是分开",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,阜阳",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "16",
        player: {
          id: "62",
          pid: "6p2rn",
          puid: "19948153",
          name: "何柳",
          nickname: "萨帝挥舞毛巾",
          header:
            "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
          height: "176",
          weight: "55",
          group: "4",
          level: "0",
          card_value: "342425199212285512",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "别撞死我就行...",
          total_income: "1",
          lightness: "10",
          tag: "",
          total_champion: "1",
          total_game: "67",
          total_win: "28",
          rate: 41,
          player_num: "11",
          group_match_num: "16",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,六安",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "17",
        player: {
          id: "61",
          pid: "vy8m6",
          puid: "29141065",
          name: "聂朋强",
          nickname: "AnthonyN7",
          header:
            "http://w3.hoopchina.com.cn/ee/90/4f/ee904f37efc386751a91598399795aac001.jpg",
          height: "183",
          weight: "125",
          group: "1",
          level: "4",
          card_value: "341227199102103491",
          birthday: "0000-00-00",
          sex: "男",
          location: "2",
          mobile: "18844120000",
          run_height: "304",
          school: "复旦",
          job: "It",
          entry_experience: "",
          entry_reflections: "回到家乡，为。",
          total_income: "2",
          lightness: "20",
          tag: "",
          total_champion: "0",
          total_game: "97",
          total_win: "39",
          rate: 40,
          player_num: "10",
          group_match_num: "17",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "北京",
          run_height_desc: "304",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,阜阳",
          style: "蝴蝶姐姐多久",
          team: "我们",
          against_with_pbk: "不带你你先弄你的",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    groups: {
      A: [
        {
          group_match_num: "1",
          player: {
            id: "52",
            pid: "nx286",
            puid: "20115310",
            name: "韦瑞恒11",
            nickname: "雾里小丑",
            header:
              "http://w3.hoopchina.com.cn/0c/8d/c8/0c8dc864267d2e33ae4c591a7413d19f002.png",
            height: "185",
            weight: "75",
            group: "2",
            level: "0",
            card_value: "341221199410010475",
            birthday: "1994-10-01",
            sex: "男",
            location: "155387",
            mobile: "18844123900",
            run_height: "320",
            school: "哈哈哈哈大学",
            job: "是吗",
            entry_experience: "那天",
            entry_reflections: "用最放松的心态迎战各路JR",
            total_income: "94",
            lightness: "940",
            tag: "",
            total_champion: "10",
            total_game: "141",
            total_win: "97",
            rate: 68,
            player_num: "1",
            group_match_num: "1",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "上海",
            run_height_desc: "320",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: "1",
            age: 26,
            constellation: "天秤座",
            title: "哈萨克海陆空三大合肥克拉还是分开",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,阜阳",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "16",
          player: {
            id: "62",
            pid: "6p2rn",
            puid: "19948153",
            name: "何柳",
            nickname: "萨帝挥舞毛巾",
            header:
              "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
            height: "176",
            weight: "55",
            group: "4",
            level: "0",
            card_value: "342425199212285512",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "别撞死我就行...",
            total_income: "1",
            lightness: "10",
            tag: "",
            total_champion: "1",
            total_game: "67",
            total_win: "28",
            rate: 41,
            player_num: "11",
            group_match_num: "16",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,六安",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "17",
          player: {
            id: "61",
            pid: "vy8m6",
            puid: "29141065",
            name: "聂朋强",
            nickname: "AnthonyN7",
            header:
              "http://w3.hoopchina.com.cn/ee/90/4f/ee904f37efc386751a91598399795aac001.jpg",
            height: "183",
            weight: "125",
            group: "1",
            level: "4",
            card_value: "341227199102103491",
            birthday: "0000-00-00",
            sex: "男",
            location: "2",
            mobile: "18844120000",
            run_height: "304",
            school: "复旦",
            job: "It",
            entry_experience: "",
            entry_reflections: "回到家乡，为。",
            total_income: "2",
            lightness: "20",
            tag: "",
            total_champion: "0",
            total_game: "97",
            total_win: "39",
            rate: 40,
            player_num: "10",
            group_match_num: "17",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "北京",
            run_height_desc: "304",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,阜阳",
            style: "蝴蝶姐姐多久",
            team: "我们",
            against_with_pbk: "不带你你先弄你的",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      B: [
        {
          group_match_num: "2",
          player: {
            id: "67",
            pid: "v1zr6",
            puid: "16353577",
            name: "王晶晶",
            nickname: "youngfolks",
            header:
              "http://w4.hoopchina.com.cn/8c/6a/f1/8c6af179ee07fd6d2dcb642a165713c8002.png@194h_194w_2e",
            height: "178",
            weight: "60",
            group: "2",
            level: "0",
            card_value: "340405199507091419",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "290",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "再不疯狂就老了",
            total_income: "2",
            lightness: "20",
            tag: "",
            total_champion: "0",
            total_game: "94",
            total_win: "42",
            rate: 44,
            player_num: "15",
            group_match_num: "2",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "290",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,淮南",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "15",
          player: {
            id: "63",
            pid: "n77ln",
            puid: "15267535",
            name: "叶攀骅",
            nickname: "leafer20",
            header:
              "http://w3.hoopchina.com.cn/c5/2e/02/c52e02024ad0a5f2681ae31b0ad1f50d001.jpg",
            height: "176",
            weight: "80",
            group: "3",
            level: "0",
            card_value: "34012319921116673x",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "201",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "至少进一球",
            total_income: "19",
            lightness: "190",
            tag: "",
            total_champion: "0",
            total_game: "73",
            total_win: "26",
            rate: 35,
            player_num: "12",
            group_match_num: "15",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "201",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,合肥",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "18",
          player: {
            id: "60",
            pid: "6lp5n",
            puid: "3631229",
            name: "崔亚伟",
            nickname: "ccyyywwww",
            header:
              "http://w3.hoopchina.com.cn/34/d7/90/34d7903ee45b5fd43023d3a001052d03001.jpg",
            height: "179",
            weight: "90",
            group: "4",
            level: "0",
            card_value: "340122198705220053",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "抽烟喝酒纹身好男人",
            total_income: "4",
            lightness: "40",
            tag: "",
            total_champion: "1",
            total_game: "100",
            total_win: "41",
            rate: 41,
            player_num: "9",
            group_match_num: "18",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,合肥",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      C: [
        {
          group_match_num: "3",
          player: {
            id: "106",
            pid: "v22dv",
            puid: "15600079",
            name: "陆志卿",
            nickname: "KGlangwang",
            header:
              "http://w4.hoopchina.com.cn/59/87/86/598786f6143561f0a4d45c0a2662cf8c001.jpg",
            height: "181",
            weight: "77",
            group: "2",
            level: "0",
            card_value: "310109199311150513",
            birthday: "0000-00-00",
            sex: "男",
            location: "上海虹口",
            mobile: "18844123900",
            run_height: "315",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "加油！",
            total_income: "0",
            lightness: "0",
            tag: "",
            total_champion: "0",
            total_game: "1",
            total_win: "0",
            rate: 0,
            player_num: "24",
            group_match_num: "3",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "315",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "14",
          player: {
            id: "64",
            pid: "nrz16",
            puid: "16280559",
            name: "梅立",
            nickname: "风也随风",
            header:
              "http://w3.hoopchina.com.cn/7b/ca/44/7bca44d0800dc452a34597cfb2a510ff001.jpg",
            height: "183",
            weight: "80",
            group: "3",
            level: "0",
            card_value: "152104199510152837",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "打个酱油",
            total_income: "2",
            lightness: "20",
            tag: "",
            total_champion: "0",
            total_game: "99",
            total_win: "45",
            rate: 45,
            player_num: "13",
            group_match_num: "14",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "内蒙古,呼伦贝尔",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "19",
          player: {
            id: "59",
            pid: "6g126",
            puid: "29329674",
            name: "刘龙锦",
            nickname: "恋茹惜锦",
            header:
              "http://w3.hoopchina.com.cn/ba/df/6d/badf6dda8efd25a41dc650b52904794b002.png",
            height: "181",
            weight: "71",
            group: "1",
            level: "0",
            card_value: "341125198911190553",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "300",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "在哪里跌倒就在哪里站起来",
            total_income: "26",
            lightness: "260",
            tag: "",
            total_champion: "1",
            total_game: "103",
            total_win: "50",
            rate: 48,
            player_num: "8",
            group_match_num: "19",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "300",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,滁州",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      D: [
        {
          group_match_num: "4",
          player: {
            id: "105",
            pid: "v0olv",
            puid: "17996334",
            name: "杨帆",
            nickname: "杨小帆馒头",
            header:
              "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
            height: "180",
            weight: "78",
            group: "3",
            level: "0",
            card_value: "342601199301180210",
            birthday: "0000-00-00",
            sex: "男",
            location: "安徽合肥",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "哈哈哈！",
            total_income: "0",
            lightness: "0",
            tag: "",
            total_champion: "0",
            total_game: "1",
            total_win: "0",
            rate: 0,
            player_num: "23",
            group_match_num: "4",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "13",
          player: {
            id: "55",
            pid: "n9yjv",
            puid: "3597970",
            name: "丁家强",
            nickname: "geoffrey0326",
            header:
              "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
            height: "177",
            weight: "79",
            group: "3",
            level: "0",
            card_value: "342221199306270016",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844123900",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "想知道在JRS里面我有多强",
            total_income: "3",
            lightness: "30",
            tag: "",
            total_champion: "1",
            total_game: "114",
            total_win: "55",
            rate: 48,
            player_num: "4",
            group_match_num: "13",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "20",
          player: {
            id: "58",
            pid: "65w26",
            puid: "17276154",
            name: "冯立",
            nickname: "勒布朗小九",
            header:
              "http://w4.hoopchina.com.cn/c6/38/40/c6384009788b72516af9d753c4a95ecf002.png",
            height: "180",
            weight: "90",
            group: "1",
            level: "0",
            card_value: "342601199301180210",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "303",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections:
              "让自己生命里留下一段让自己回忆起来都热泪盈眶的日子",
            total_income: "1",
            lightness: "10",
            tag: "",
            total_champion: "2",
            total_game: "106",
            total_win: "45",
            rate: 42,
            player_num: "7",
            group_match_num: "20",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "303",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      E: [
        {
          group_match_num: "5",
          player: {
            id: "103",
            pid: "nd74n",
            puid: "29240579",
            name: "张俊豪",
            nickname: "书生Awesome",
            header:
              "http://i1.hoopchina.com.cn/user/579/29240579/29240579_big_5.png",
            height: "181",
            weight: "78",
            group: "2",
            level: "0",
            card_value: "360681199206018016",
            birthday: "0000-00-00",
            sex: "男",
            location: "上海",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections:
              "所有花哨的动作，都要建立在命中率准的前提下。否则都是白搭。",
            total_income: "169",
            lightness: "1690",
            tag: "",
            total_champion: "0",
            total_game: "56",
            total_win: "21",
            rate: 37,
            player_num: "22",
            group_match_num: "5",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "江西,鹰潭",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "12",
          player: {
            id: "65",
            pid: "6mp56",
            puid: "18693815",
            name: "王亚东",
            nickname: "王yD",
            header:
              "http://w1.hoopchina.com.cn/a2/e0/d5/a2e0d589c0d34b89270e2f5c2fa9658b002.png",
            height: "186",
            weight: "75",
            group: "1",
            level: "0",
            card_value: "610112199210030511",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "305",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "积极投身热爱的运动！",
            total_income: "1",
            lightness: "10",
            tag: "",
            total_champion: "0",
            total_game: "69",
            total_win: "23",
            rate: 33,
            player_num: "14",
            group_match_num: "12",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "305",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "，有已淘汰标记球面3333",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "陕西,西安",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "21",
          player: {
            id: "57",
            pid: "n4oz6",
            puid: "28465483",
            name: "倪宇豪",
            nickname: "专治各种装神弄鬼",
            header:
              "http://w2.hoopchina.com.cn/d0/9c/3b/d09c3b450ee6f202435c920357f5483e001.jpg",
            height: "180",
            weight: "76",
            group: "4",
            level: "0",
            card_value: "34290119910706081x",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "310",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "永远追寻篮球梦",
            total_income: "11",
            lightness: "110",
            tag: "kebi",
            total_champion: "1",
            total_game: "92",
            total_win: "37",
            rate: 40,
            player_num: "6",
            group_match_num: "21",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "310",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,池州",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      F: [
        {
          group_match_num: "6",
          player: {
            id: "102",
            pid: "6j3lv",
            puid: "28658032",
            name: "马鹤醇",
            nickname: "卖萌的Kobe",
            header:
              "http://w3.hoopchina.com.cn/1a/41/19/1a411985f302789c8cd4ca76cac1a36a001.jpg",
            height: "173",
            weight: "60",
            group: "4",
            level: "0",
            card_value: "310102198909191273",
            birthday: "0000-00-00",
            sex: "男",
            location: "上海",
            mobile: "18550213926",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "冠军只有一个，那人为什么不能是我",
            total_income: "0",
            lightness: "0",
            tag: "",
            total_champion: "1",
            total_game: "48",
            total_win: "20",
            rate: 41,
            player_num: "21",
            group_match_num: "6",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员-修改后",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "11",
          player: {
            id: "68",
            pid: "nz8en",
            puid: "29337177",
            name: "武建树",
            nickname: "演员KD",
            header:
              "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
            height: "178",
            weight: "120",
            group: "4",
            level: "0",
            card_value: "342221199112206075",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "315",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "不训练三峡",
            total_income: "1",
            lightness: "10",
            tag: "",
            total_champion: "2",
            total_game: "77",
            total_win: "30",
            rate: 38,
            player_num: "16",
            group_match_num: "11",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "315",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "22",
          player: {
            id: "56",
            pid: "n8l9v",
            puid: "647802",
            name: "刘海",
            nickname: "sako001",
            header:
              "http://w4.hoopchina.com.cn/fc/74/35/fc7435a227fdefb1c3513c3a3586ffdf002.png",
            height: "176",
            weight: "85",
            group: "3",
            level: "0",
            card_value: "341125199603308833",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "先拿2000垫垫肚子！",
            total_income: "175",
            lightness: "1750",
            tag: "",
            total_champion: "2",
            total_game: "94",
            total_win: "42",
            rate: 44,
            player_num: "5",
            group_match_num: "22",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,滁州",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      G: [
        {
          group_match_num: "7",
          player: {
            id: "100",
            pid: "6311n",
            puid: "18599980",
            name: "徐亦飞",
            nickname: "齐茂之朋友",
            header:
              "http://w4.hoopchina.com.cn/1c/cc/2e/1ccc2eae07bff261cad53cde47756b6e001.jpg",
            height: "178",
            weight: "68",
            group: "3",
            level: "0",
            card_value: "32050319851102001X",
            birthday: "0000-00-00",
            sex: "男",
            location: "江苏苏州",
            mobile: "18844120000",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "我要见安娜 哈哈",
            total_income: "0",
            lightness: "0",
            tag: "",
            total_champion: "0",
            total_game: "55",
            total_win: "23",
            rate: 41,
            player_num: "20",
            group_match_num: "7",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "江苏,苏州",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "10",
          player: {
            id: "69",
            pid: "vqmln",
            puid: "29196286",
            name: "洪敔声",
            nickname: "哈哈岛主",
            header:
              "http://w3.hoopchina.com.cn/72/9e/0b/729e0bd0c3893314cdbc897ed417240b002.png",
            height: "187",
            weight: "90",
            group: "4",
            level: "0",
            card_value: "34010419941028203X",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "18844120000",
            run_height: "310",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "未来反正不是我的~",
            total_income: "32",
            lightness: "320",
            tag: "",
            total_champion: "3",
            total_game: "88",
            total_win: "41",
            rate: 46,
            player_num: "17",
            group_match_num: "10",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "310",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,合肥",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "23",
          player: {
            id: "92819",
            pid: "11e49",
            puid: "40065469",
            name: "new标签球员",
            nickname: "new标签球员",
            header:
              "http://w1.hoopchina.com.cn/huputv/resource/img/amateur.jpg",
            height: "180",
            weight: "90",
            group: "0",
            level: "0",
            card_value: "",
            birthday: "0000-00-00",
            sex: "男",
            location: "",
            mobile: "19920209800",
            run_height: "0",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "",
            total_income: "0",
            lightness: "0",
            tag: "",
            total_champion: "1",
            total_game: "7",
            total_win: "6",
            rate: 85,
            player_num: "25",
            group_match_num: "23",
            paid: 0,
            odds: "1.0",
            hide: "1",
            status: "1",
            location_name: "",
            run_height_desc: "0",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
      ],
      H: [
        {
          group_match_num: "8",
          player: {
            id: "98",
            pid: "6j35v",
            puid: "19639242",
            name: "欧阳奇凡",
            nickname: "可爱小果冻",
            header:
              "http://w1.hoopchina.com.cn/9b/de/22/9bde22f3add1f3e17025b1918b726be1002.png",
            height: "175",
            weight: "72",
            group: "4",
            level: "0",
            card_value: "340403199608221814",
            birthday: "0000-00-00",
            sex: "男",
            location: "安徽淮南",
            mobile: "18844120000",
            run_height: "300",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "加油",
            total_income: "1",
            lightness: "10",
            tag: "",
            total_champion: "1",
            total_game: "69",
            total_win: "34",
            rate: 49,
            player_num: "19",
            group_match_num: "8",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "300",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "安徽,淮南",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        {
          group_match_num: "9",
          player: {
            id: "97",
            pid: "6elpn",
            puid: "29073572",
            name: "黄家豪",
            nickname: "丰满版波什",
            header:
              "http://w1.hoopchina.com.cn/2a/96/62/2a96626c0dbba00c270f56b3ad878fb4002.png",
            height: "186",
            weight: "110",
            group: "1",
            level: "0",
            card_value: "310230199506230851",
            birthday: "0000-00-00",
            sex: "男",
            location: "上海宝山",
            mobile: "18844120000",
            run_height: "210",
            school: "",
            job: "",
            entry_experience: "",
            entry_reflections: "只求不垫底",
            total_income: "0",
            lightness: "0",
            tag: "",
            total_champion: "4",
            total_game: "55",
            total_win: "31",
            rate: 56,
            player_num: "18",
            group_match_num: "9",
            paid: 0,
            odds: "1.1",
            hide: "1",
            status: "2",
            location_name: "",
            run_height_desc: "210",
            player_type: 0,
            player_type_rank: 0,
            power_rank: 0,
            sign: 0,
            age: 0,
            constellation: "摩羯座",
            title: "神秘实力球员",
            label: "",
            douyin: "",
            kuaishou: "",
            weibo: "",
            card_location: "",
            style: "",
            team: "",
            against_with_pbk: "",
            lrw_cert: "",
            active: false,
          },
        },
        { group_match_num: 24, player: { active: false } },
      ],
    },
    hide: "1",
    B: [
      {
        group_match_num: "2",
        player: {
          id: "67",
          pid: "v1zr6",
          puid: "16353577",
          name: "王晶晶",
          nickname: "youngfolks",
          header:
            "http://w4.hoopchina.com.cn/8c/6a/f1/8c6af179ee07fd6d2dcb642a165713c8002.png@194h_194w_2e",
          height: "178",
          weight: "60",
          group: "2",
          level: "0",
          card_value: "340405199507091419",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "290",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "再不疯狂就老了",
          total_income: "2",
          lightness: "20",
          tag: "",
          total_champion: "0",
          total_game: "94",
          total_win: "42",
          rate: 44,
          player_num: "15",
          group_match_num: "2",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "290",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,淮南",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "15",
        player: {
          id: "63",
          pid: "n77ln",
          puid: "15267535",
          name: "叶攀骅",
          nickname: "leafer20",
          header:
            "http://w3.hoopchina.com.cn/c5/2e/02/c52e02024ad0a5f2681ae31b0ad1f50d001.jpg",
          height: "176",
          weight: "80",
          group: "3",
          level: "0",
          card_value: "34012319921116673x",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "201",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "至少进一球",
          total_income: "19",
          lightness: "190",
          tag: "",
          total_champion: "0",
          total_game: "73",
          total_win: "26",
          rate: 35,
          player_num: "12",
          group_match_num: "15",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "201",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,合肥",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "18",
        player: {
          id: "60",
          pid: "6lp5n",
          puid: "3631229",
          name: "崔亚伟",
          nickname: "ccyyywwww",
          header:
            "http://w3.hoopchina.com.cn/34/d7/90/34d7903ee45b5fd43023d3a001052d03001.jpg",
          height: "179",
          weight: "90",
          group: "4",
          level: "0",
          card_value: "340122198705220053",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "抽烟喝酒纹身好男人",
          total_income: "4",
          lightness: "40",
          tag: "",
          total_champion: "1",
          total_game: "100",
          total_win: "41",
          rate: 41,
          player_num: "9",
          group_match_num: "18",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,合肥",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    C: [
      {
        group_match_num: "3",
        player: {
          id: "106",
          pid: "v22dv",
          puid: "15600079",
          name: "陆志卿",
          nickname: "KGlangwang",
          header:
            "http://w4.hoopchina.com.cn/59/87/86/598786f6143561f0a4d45c0a2662cf8c001.jpg",
          height: "181",
          weight: "77",
          group: "2",
          level: "0",
          card_value: "310109199311150513",
          birthday: "0000-00-00",
          sex: "男",
          location: "上海虹口",
          mobile: "18844123900",
          run_height: "315",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "加油！",
          total_income: "0",
          lightness: "0",
          tag: "",
          total_champion: "0",
          total_game: "1",
          total_win: "0",
          rate: 0,
          player_num: "24",
          group_match_num: "3",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "315",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "14",
        player: {
          id: "64",
          pid: "nrz16",
          puid: "16280559",
          name: "梅立",
          nickname: "风也随风",
          header:
            "http://w3.hoopchina.com.cn/7b/ca/44/7bca44d0800dc452a34597cfb2a510ff001.jpg",
          height: "183",
          weight: "80",
          group: "3",
          level: "0",
          card_value: "152104199510152837",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "打个酱油",
          total_income: "2",
          lightness: "20",
          tag: "",
          total_champion: "0",
          total_game: "99",
          total_win: "45",
          rate: 45,
          player_num: "13",
          group_match_num: "14",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "内蒙古,呼伦贝尔",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "19",
        player: {
          id: "59",
          pid: "6g126",
          puid: "29329674",
          name: "刘龙锦",
          nickname: "恋茹惜锦",
          header:
            "http://w3.hoopchina.com.cn/ba/df/6d/badf6dda8efd25a41dc650b52904794b002.png",
          height: "181",
          weight: "71",
          group: "1",
          level: "0",
          card_value: "341125198911190553",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "300",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "在哪里跌倒就在哪里站起来",
          total_income: "26",
          lightness: "260",
          tag: "",
          total_champion: "1",
          total_game: "103",
          total_win: "50",
          rate: 48,
          player_num: "8",
          group_match_num: "19",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "300",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,滁州",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    D: [
      {
        group_match_num: "4",
        player: {
          id: "105",
          pid: "v0olv",
          puid: "17996334",
          name: "杨帆",
          nickname: "杨小帆馒头",
          header:
            "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
          height: "180",
          weight: "78",
          group: "3",
          level: "0",
          card_value: "342601199301180210",
          birthday: "0000-00-00",
          sex: "男",
          location: "安徽合肥",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "哈哈哈！",
          total_income: "0",
          lightness: "0",
          tag: "",
          total_champion: "0",
          total_game: "1",
          total_win: "0",
          rate: 0,
          player_num: "23",
          group_match_num: "4",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "13",
        player: {
          id: "55",
          pid: "n9yjv",
          puid: "3597970",
          name: "丁家强",
          nickname: "geoffrey0326",
          header:
            "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
          height: "177",
          weight: "79",
          group: "3",
          level: "0",
          card_value: "342221199306270016",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844123900",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "想知道在JRS里面我有多强",
          total_income: "3",
          lightness: "30",
          tag: "",
          total_champion: "1",
          total_game: "114",
          total_win: "55",
          rate: 48,
          player_num: "4",
          group_match_num: "13",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "20",
        player: {
          id: "58",
          pid: "65w26",
          puid: "17276154",
          name: "冯立",
          nickname: "勒布朗小九",
          header:
            "http://w4.hoopchina.com.cn/c6/38/40/c6384009788b72516af9d753c4a95ecf002.png",
          height: "180",
          weight: "90",
          group: "1",
          level: "0",
          card_value: "342601199301180210",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "303",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections:
            "让自己生命里留下一段让自己回忆起来都热泪盈眶的日子",
          total_income: "1",
          lightness: "10",
          tag: "",
          total_champion: "2",
          total_game: "106",
          total_win: "45",
          rate: 42,
          player_num: "7",
          group_match_num: "20",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "303",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    E: [
      {
        group_match_num: "5",
        player: {
          id: "103",
          pid: "nd74n",
          puid: "29240579",
          name: "张俊豪",
          nickname: "书生Awesome",
          header:
            "http://i1.hoopchina.com.cn/user/579/29240579/29240579_big_5.png",
          height: "181",
          weight: "78",
          group: "2",
          level: "0",
          card_value: "360681199206018016",
          birthday: "0000-00-00",
          sex: "男",
          location: "上海",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections:
            "所有花哨的动作，都要建立在命中率准的前提下。否则都是白搭。",
          total_income: "169",
          lightness: "1690",
          tag: "",
          total_champion: "0",
          total_game: "56",
          total_win: "21",
          rate: 37,
          player_num: "22",
          group_match_num: "5",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "江西,鹰潭",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "12",
        player: {
          id: "65",
          pid: "6mp56",
          puid: "18693815",
          name: "王亚东",
          nickname: "王yD",
          header:
            "http://w1.hoopchina.com.cn/a2/e0/d5/a2e0d589c0d34b89270e2f5c2fa9658b002.png",
          height: "186",
          weight: "75",
          group: "1",
          level: "0",
          card_value: "610112199210030511",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "305",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "积极投身热爱的运动！",
          total_income: "1",
          lightness: "10",
          tag: "",
          total_champion: "0",
          total_game: "69",
          total_win: "23",
          rate: 33,
          player_num: "14",
          group_match_num: "12",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "305",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "，有已淘汰标记球面3333",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "陕西,西安",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "21",
        player: {
          id: "57",
          pid: "n4oz6",
          puid: "28465483",
          name: "倪宇豪",
          nickname: "专治各种装神弄鬼",
          header:
            "http://w2.hoopchina.com.cn/d0/9c/3b/d09c3b450ee6f202435c920357f5483e001.jpg",
          height: "180",
          weight: "76",
          group: "4",
          level: "0",
          card_value: "34290119910706081x",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "310",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "永远追寻篮球梦",
          total_income: "11",
          lightness: "110",
          tag: "kebi",
          total_champion: "1",
          total_game: "92",
          total_win: "37",
          rate: 40,
          player_num: "6",
          group_match_num: "21",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "310",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,池州",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    F: [
      {
        group_match_num: "6",
        player: {
          id: "102",
          pid: "6j3lv",
          puid: "28658032",
          name: "马鹤醇",
          nickname: "卖萌的Kobe",
          header:
            "http://w3.hoopchina.com.cn/1a/41/19/1a411985f302789c8cd4ca76cac1a36a001.jpg",
          height: "173",
          weight: "60",
          group: "4",
          level: "0",
          card_value: "310102198909191273",
          birthday: "0000-00-00",
          sex: "男",
          location: "上海",
          mobile: "18550213926",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "冠军只有一个，那人为什么不能是我",
          total_income: "0",
          lightness: "0",
          tag: "",
          total_champion: "1",
          total_game: "48",
          total_win: "20",
          rate: 41,
          player_num: "21",
          group_match_num: "6",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员-修改后",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "11",
        player: {
          id: "68",
          pid: "nz8en",
          puid: "29337177",
          name: "武建树",
          nickname: "演员KD",
          header:
            "http://w2.hoopchina.com.cn/43/6f/6a/436f6a5aa8a38e158b98830a3b5c4a4b001.jpg",
          height: "178",
          weight: "120",
          group: "4",
          level: "0",
          card_value: "342221199112206075",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "315",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "不训练三峡",
          total_income: "1",
          lightness: "10",
          tag: "",
          total_champion: "2",
          total_game: "77",
          total_win: "30",
          rate: 38,
          player_num: "16",
          group_match_num: "11",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "315",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "22",
        player: {
          id: "56",
          pid: "n8l9v",
          puid: "647802",
          name: "刘海",
          nickname: "sako001",
          header:
            "http://w4.hoopchina.com.cn/fc/74/35/fc7435a227fdefb1c3513c3a3586ffdf002.png",
          height: "176",
          weight: "85",
          group: "3",
          level: "0",
          card_value: "341125199603308833",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "先拿2000垫垫肚子！",
          total_income: "175",
          lightness: "1750",
          tag: "",
          total_champion: "2",
          total_game: "94",
          total_win: "42",
          rate: 44,
          player_num: "5",
          group_match_num: "22",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,滁州",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    G: [
      {
        group_match_num: "7",
        player: {
          id: "100",
          pid: "6311n",
          puid: "18599980",
          name: "徐亦飞",
          nickname: "齐茂之朋友",
          header:
            "http://w4.hoopchina.com.cn/1c/cc/2e/1ccc2eae07bff261cad53cde47756b6e001.jpg",
          height: "178",
          weight: "68",
          group: "3",
          level: "0",
          card_value: "32050319851102001X",
          birthday: "0000-00-00",
          sex: "男",
          location: "江苏苏州",
          mobile: "18844120000",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "我要见安娜 哈哈",
          total_income: "0",
          lightness: "0",
          tag: "",
          total_champion: "0",
          total_game: "55",
          total_win: "23",
          rate: 41,
          player_num: "20",
          group_match_num: "7",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "江苏,苏州",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "10",
        player: {
          id: "69",
          pid: "vqmln",
          puid: "29196286",
          name: "洪敔声",
          nickname: "哈哈岛主",
          header:
            "http://w3.hoopchina.com.cn/72/9e/0b/729e0bd0c3893314cdbc897ed417240b002.png",
          height: "187",
          weight: "90",
          group: "4",
          level: "0",
          card_value: "34010419941028203X",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "18844120000",
          run_height: "310",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "未来反正不是我的~",
          total_income: "32",
          lightness: "320",
          tag: "",
          total_champion: "3",
          total_game: "88",
          total_win: "41",
          rate: 46,
          player_num: "17",
          group_match_num: "10",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "310",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,合肥",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "23",
        player: {
          id: "92819",
          pid: "11e49",
          puid: "40065469",
          name: "new标签球员",
          nickname: "new标签球员",
          header: "http://w1.hoopchina.com.cn/huputv/resource/img/amateur.jpg",
          height: "180",
          weight: "90",
          group: "0",
          level: "0",
          card_value: "",
          birthday: "0000-00-00",
          sex: "男",
          location: "",
          mobile: "19920209800",
          run_height: "0",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "",
          total_income: "0",
          lightness: "0",
          tag: "",
          total_champion: "1",
          total_game: "7",
          total_win: "6",
          rate: 85,
          player_num: "25",
          group_match_num: "23",
          paid: 0,
          odds: "1.0",
          hide: "1",
          status: "1",
          location_name: "",
          run_height_desc: "0",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
    ],
    H: [
      {
        group_match_num: "8",
        player: {
          id: "98",
          pid: "6j35v",
          puid: "19639242",
          name: "欧阳奇凡",
          nickname: "可爱小果冻",
          header:
            "http://w1.hoopchina.com.cn/9b/de/22/9bde22f3add1f3e17025b1918b726be1002.png",
          height: "175",
          weight: "72",
          group: "4",
          level: "0",
          card_value: "340403199608221814",
          birthday: "0000-00-00",
          sex: "男",
          location: "安徽淮南",
          mobile: "18844120000",
          run_height: "300",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "加油",
          total_income: "1",
          lightness: "10",
          tag: "",
          total_champion: "1",
          total_game: "69",
          total_win: "34",
          rate: 49,
          player_num: "19",
          group_match_num: "8",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "300",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "安徽,淮南",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      {
        group_match_num: "9",
        player: {
          id: "97",
          pid: "6elpn",
          puid: "29073572",
          name: "黄家豪",
          nickname: "丰满版波什",
          header:
            "http://w1.hoopchina.com.cn/2a/96/62/2a96626c0dbba00c270f56b3ad878fb4002.png",
          height: "186",
          weight: "110",
          group: "1",
          level: "0",
          card_value: "310230199506230851",
          birthday: "0000-00-00",
          sex: "男",
          location: "上海宝山",
          mobile: "18844120000",
          run_height: "210",
          school: "",
          job: "",
          entry_experience: "",
          entry_reflections: "只求不垫底",
          total_income: "0",
          lightness: "0",
          tag: "",
          total_champion: "4",
          total_game: "55",
          total_win: "31",
          rate: 56,
          player_num: "18",
          group_match_num: "9",
          paid: 0,
          odds: "1.1",
          hide: "1",
          status: "2",
          location_name: "",
          run_height_desc: "210",
          player_type: 0,
          player_type_rank: 0,
          power_rank: 0,
          sign: 0,
          age: 0,
          constellation: "摩羯座",
          title: "神秘实力球员",
          label: "",
          douyin: "",
          kuaishou: "",
          weibo: "",
          card_location: "",
          style: "",
          team: "",
          against_with_pbk: "",
          lrw_cert: "",
          active: false,
        },
      },
      { group_match_num: 24, player: { active: false } },
    ],
  },
};
