import React, { Component } from "react";
import Nav from "../../component/navigation";
import { withRouter } from "react-router-dom";
import CopyRight from "../../component/copyRIght";

import "./index.scss";
import card from "./img/card.png";
import player from "./img/player.png";
import email from "./img/email.png";
import phone from "./img/phone.png";

class Index extends Component {
  /**
   * [render description]
   * @return {[type]} [description]
   */

  componentDidMount() {}

  render() {
    return (
      <div className="contactPage">
        <Nav active={2} />
        <main>
          <div className="card">
            <img src={card} />
            <img src={player} className="player" />

            <div className="info">
              <div className="email">
                <div className="icon">
                  <img src={email} />
                </div>
                <div className="title">E - mail</div>
                <div className="detail">media@lurenwang.com</div>
              </div>
              <div className="telephone">
                <div className="icon">
                  <img src={phone} />
                </div>
                <div className="title">telephone</div>
                <div className="detail">+86 15021499319</div>
              </div>
            </div>
          </div>
        </main>
        <CopyRight active={2} />
      </div>
    );
  }
}

export default Index;
