import DPlayer from "dplayer";
import QRCode from "qrcode.react";
import React, {useEffect, useState} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {Col, Container, Row, setConfiguration} from "react-grid-system";
import {
    getGamePlayerList,
    getVideoList,
    getVideoPlayUrl,
    getGamePlayerListJava,
} from "../../api/pc";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {getQuery} from "../../utils";
import {PlayerCard} from "../LiveRoom";
import {player_list} from "../LiveRoom/player_list";
import link from "./img/link.jpg";
import qq from "./img/qq.jpg";
import qzone from "./img/qzone.jpg";
import sina from "./img/sina.jpg";
import wx from "./img/wx.jpg";
import icon_video from "./img/icon_video.png";
import "./style.scss";
import {videolist} from "./videolist";
import {nav_video_page} from "../VideoList/nav-video-page";
import Script from "react-load-script";

const VideoPlayer = ({video}) => {
    console.log("videoPlayer:", video);
    let url = video["playUrls"] ? (video["playUrls"]["sc"] || "") : "";
    let is_hls = url.includes(".m3u8") || false;
    const load_dplayer = () => {
        console.log(video);
        let cover = video["cover"] ? (video["cover"] || "") : "";
        if (url && document.getElementById("dplayer")) {
            const dp = new DPlayer({
                container: document.getElementById("dplayer"),
                autoplay: false,
                lang: "zh-cn",
                video: {
                    url: url,
                    pic: cover,
                    type: is_hls ? "hls" : "auto",
                },
                contextmenu: [],
            });
        }
    };
    useEffect(() => {
        console.log("2222");
        // load_video_data();
        load_dplayer();
    }, [video]);
    return (
        <>
            {
                is_hls ? <Script
                    url="https://cdn.bootcdn.net/ajax/libs/hls.js/1.0.8-0.canary.7646/hls.min.js"
                    onLoad={load_dplayer.bind(this)}
                /> : null
            }
            {(
                <div className="player-container" style={{}}>
                    <div
                        id="dplayer"
                        style={
                            {
                                // marginTop: 40,
                            }
                        }
                    ></div>
                </div>
            )}
        </>
    );
};
const ShareComp = ({title}) => {
    const on_copy = () => {
        console.log("on_copy :>> ");
    };
    let qr_url = window.location.href;
    const [is_show_qr, set_is_show_qr] = useState(false);
    const on_over_qr = (v) => {
        set_is_show_qr(v);
    };
    const on_qq = () => {
        window.open(
            `https://connect.qq.com/widget/shareqq/index.html?url=${escape(
                qr_url
            )}&title=${title}`
        );
    };
    const on_qzone = () => {
        window.open(
            `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${escape(
                qr_url
            )}&title=${title}`
        );
    };
    const on_sina = () => {
        window.open(
            `https://service.weibo.com/share/share.php?url=${escape(
                qr_url
            )}&title=${title}&searchPic=true&appkey=372433789`
        );
    };
    return (
        <div style={{marginTop: 40}}>
            <Container>
                <Row justify="end">
                    <Col sm={8}>
                        <div
                            className="gray-title "
                            style={{marginTop: 4, right: 0, position: "relative"}}
                        >
                            分享至
                        </div>
                    </Col>
                    <Col sm={3}>
                        <CopyToClipboard
                            text={window.location.href}
                            onCopy={() => on_copy()}
                        >
                            <img src={link} className="pointer"/>
                        </CopyToClipboard>
                    </Col>
                    <Col sm={3}>
                        <img
                            src={wx}
                            onMouseOver={() => on_over_qr(true)}
                            onMouseLeave={() => on_over_qr(false)}
                        />
                        {is_show_qr && (
                            <div
                                style={{
                                    padding: 20,
                                    backgroundColor: "#fff",
                                    width: 180,
                                    height: 220,
                                    zIndex: 999,
                                    position: "relative",
                                }}
                            >
                <span
                    className="gray-title"
                    style={{fontSize: 18, margin: "auto"}}
                >
                  微信扫一扫
                </span>
                                <QRCode
                                    id="qrCode"
                                    value={qr_url}
                                    size={180} // 二维码的大小
                                    fgColor="#000000" // 二维码的颜色
                                    style={{
                                        margin: "auto",
                                    }}
                                    imageSettings={{
                                        // 二维码中间的logo图片
                                        src: "logoUrl",
                                        height: 100,
                                        width: 100,
                                    }}
                                />
                            </div>
                        )}
                    </Col>
                    <Col sm={3}>
                        <div className="pointer" onClick={on_qq}>
                            <img src={qq}/>
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="pointer" onClick={on_qzone}>
                            <img src={qzone}/>
                        </div>
                    </Col>

                    <Col sm={3}>
                        {/* on_sina */}
                        <div className="pointer" onClick={on_sina}>
                            <img src={sina}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
const get_vid_item = async () => {
    const vid = getQuery("vid");
    let res = await getVideoPlayUrl(vid);
    return res["result"];
};
const PlayerSection = ({video_arr}) => {
    const [current_video, set_current_video] = useState({});
    const [state, setState] = useState({
        title: "",
        playNum: 0,
        createDate: "",
    });
    const load_video_list = async () => {
        let item = await get_vid_item();
        if (item) {
            console.log("视频列表 当前视频 :>> ", item);
            set_current_video(item);
            let date = new Date(item["createDate"] || "");
            let currentYear = new Date().getFullYear();
            let dateStr = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
            if (date.getFullYear() === currentYear) {
                dateStr = (date.getMonth() + 1) + "/" + date.getDate();
            }
            setState({
                title: item["title"],
                playNum: item["playNum"],
                createDate: dateStr,
            });
        }
    };
    useEffect(() => {
        load_video_list();
    }, []);
    return (
        <>
            <Col sm={24}>
                <div className="player-section">
                    <VideoPlayer {...{video: current_video}}/>
                    <div className="card">
                        <Row>
                            <Col sm={14}>
                                <div className="title">{state.title}</div>
                                <Row>
                                    <Col sm={6}>
                                        <div className="gray-title">{state.playNum}播放</div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="gray-title">{state.createDate}</div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row justify="end">
                                    <Col>
                                        <ShareComp {...{title: state.title}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </>
    );
};
const VideoSection = ({video_arr}) => {
    const on_vid = (item) => {
        nav_video_page(item);
    };
    return (
        <div className="video-section">
            <div className="flexbox" style={{marginLeft: 15}}>
                <img src={icon_video} style={{marginTop: 2, marginRight: 20}}></img>
                <div className="title">推荐视频</div>
            </div>
            {video_arr.map((it, idx) => {
                let currentYear = new Date().getFullYear();
                let date = new Date(it.createDate);
                let dateStr = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
                if (date.getFullYear() === currentYear) {
                    dateStr = (date.getMonth() + 1) + "/" + date.getDate();
                }
                return (
                    <div key={idx} className="card pointer" onClick={() => on_vid(it)}>
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <img src={it.cover} style={{borderRadius: 5}}></img>
                                </Col>
                                <Col style={{marginTop: 0, marginLeft: -5}}>
                                    <div className="title">{it.title}</div>
                                    <Row justify="between">
                                        <Col>
                                            <div className="gray-title">{it.playNum}播放</div>
                                        </Col>
                                        <Col>
                                            <div className="gray-title">{dateStr}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                );
            })}
        </div>
    );
};
const PlayerListSection = () => {
    let game_id = getQuery("game_id");

    const [state, setState] = useState({
        player_arr: [],
    });
    const group_name = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    const load_player_list = async () => {
        let res;
        if (getQuery("test") === "1") res = player_list;
        else {
            const get_player_list = async () => {
                let game_id = getQuery("game_id");
                if (game_id) {
                    setState({...state, game_id});
                } else {
                    game_id = state.game_id;
                }
                console.log("球员列表 刷新开始 :>> ", game_id);
                let res = await getGamePlayerListJava(game_id);
                let c = res["result"]["playerList"];
                console.log("球员列表 刷新数据 :>> ", c);
                let a = [];
                let all_player_arr = [];
                for (let group of group_name) {
                    let player_arr = [];
                    for (let i = 0; i < 3; i++) {
                        if (c.length > 0) {
                            let p = c.shift();
                            console.log("球员：" + p.name);
                            let item = {group, player: p};
                            player_arr.push(item);
                        }
                    }

                    all_player_arr = [...all_player_arr, ...player_arr];
                    if (player_arr.length > 0) {
                        a.push({group, player_arr});
                    }
                }
                setState({player_arr: all_player_arr});
            };
            get_player_list();
        }
    };

    useEffect(() => {
        load_player_list();
    }, []);
    if (!game_id) return <></>;
    return (
        <>
            <Row style={{marginTop: 30}}>
                <Col sm={24}>
                    <div className="player-list-section">
                        <div className="title">球员统计</div>
                    </div>
                </Col>
                {state.player_arr.map((it, i) => (
                    <Col sm={12} key={i}>
                        <PlayerCard {...{player: it.player}} />
                    </Col>
                ))}
            </Row>
        </>
    );
};
export default function VideoRoom({history}) {
    setConfiguration({
        defaultScreenClass: "sm",
        gutterWidth: 30,
        gridColumns: 24,
    });
    const [video_arr, set_video_arr] = useState([]);
    const load_video_arr = async () => {
        let res;
        if (getQuery("test") === "1") res = videolist;
        else {
            res = await getVideoList({current: 1, size: 15});
        }
        console.log("视频列表 :>> ", res["result"]["data"]);
        set_video_arr(res["result"]["data"]);
    };
    useEffect(() => {
        load_video_arr();
    }, []);
    return (
        <div>
            <Header location={history.location}></Header>
            {video_arr.length && (
                <Container style={{marginBottom: 20}}>
                    <Row>
                        <Col sm={16}>
                            <div style={{marginTop: 16}}>
                                {/* <Row> */}
                                <PlayerSection {...{video_arr}} />
                                {/* </Row> */}

                                <PlayerListSection></PlayerListSection>
                                <Row>
                                    <Col sm={24}></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col sm={8}>
                            <VideoSection {...{video_arr}}></VideoSection>
                        </Col>
                    </Row>
                </Container>
            )}
            <Footer></Footer>
        </div>
    );
}
