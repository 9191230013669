import H5Iframe from "../../components/h5-iframe";
import { env_is_sit, env_is_stg, env_is_prd } from "../../utils/env";

export default function Signtip({ history }) {
  let url = env_is_sit()
  ? "https://h5-sit.lurenwang.com/h5/signtip"
  : env_is_stg()
  ? "https://h5-stg.lurenwang.com/h5/signtip"
  : "https://h5.lurenwang.com/h5/signtip";
  let height = (env_is_prd()||env_is_sit()||env_is_stg())?"1598":"700px";
  return <H5Iframe {...{ history, url, height }}></H5Iframe>;
}
