import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

import {banner} from './img/imgs'
import './style.scss'

export default function About({history}) {
    return (
        <div className="about-container">
            <Header location={history.location}/>
            <img src={banner} alt="banner" className="banner"/>
            <div className="content">
                <div className="des">
                    <div className="row"><b>简介</b></div>
                    <div className="row">路人王（英文名称：The King Of
                        Underdogs）是由上海路人王信息科技有限公司打造的国产篮球公开赛事品牌，于2016年创赛。赛事⾯向⼴⼤篮球⼈群公开报名，赛制以一对一为核心，兼顾其它赛制。经过多年的持续发展，路⼈王赛事⾜迹覆盖全国所有省市，辐射海外。
                    </div>
                    <div className="row padding-left-30">
                        ➢ 中文名：路人王篮球
                        <br/> ➢ 主办机构：上海路人王信息科技有限公司
                        <br/> ➢ 举办地点：非固定
                        <br/> ➢ 创赛时间：2016
                        <br/> ➢ 品牌使命：全⼒以赴，成就⾃我
                        <br/> ➢ 品牌愿景：成为全球年轻⼈最喜爱的篮球IP
                        <br/> ➢ 参与球员：40万+注册球员
                        <br/> ➢ 办赛场次：3000+场次
                        <br/> ➢ 联名球馆：160余家联名球馆
                    </div>
                    <div className="row"><b>赛制玩法</b></div>
                    <div
                        className="row">区别于传统篮球的全场5V5赛制，路人王以半场1V1为核心主体玩法，兼顾其他赛制。并使⽤FIBA国际篮联技术委员会委员曾洪涛博⼠参与指导制定全球⾸部完善的1对1篮球规则。
                    </div>
                    <div className="row"><b>发展历程</b></div>
                    <div className="row">
                        2016年8月9日，路人王赛事IP创立，第一场以1对1为核心赛制的路人王比赛在上海举行；<br/>
                        2017年1月， 路⼈王2016年度总决赛成功举办，线上观众超100万⼈次；<br/>
                        2017年10月， 路⼈王女球员1对1专场女神赛S1成功举办；<br/>
                        2018年9 月， 路⼈王2018赛季引入“国际邀请赛”赛制，⾼⽔平外籍球员开始⼤量出现在路⼈王赛场上，中外对抗成为焦点；<br/>
                        2019年5月，完成路人王1对1篮球规则的全部定义，签约中国篮协裁判委员会常委兼规则组组长、国际级篮球裁判曾洪涛担任路人王规则与裁判问题顾问；<br/>
                        2019年9月， 路人王开始与优秀球员、篮球达⼈签约，启动路⼈王球员MCN业务，同时路⼈王校园赛事线“奔袭”正式推出，
                        第⼀次走进校园；<br/>
                        2020年5月，路人王2020赛季启动，球员积分榜正式启用至今；<br/>
                        2021年2 月，获得今日资本A轮千万美元融资，荣获抖音最具影响力赛事称号；<br/>
                        2021年12月，路⼈王注册球员⼈数达到15 万，全国范围内联名球馆数 量达到150家；抖⾳后台路⼈ 王账号季度影响⼒⾸次成为
                        篮球类第⼀、体育类第⼆；<br/>
                        2022年2月，路⼈王⻘少年篮球赛正式推出，采⽤1 对1、团队赛两种形式，覆盖U6到U18等7个年龄组别；<br/>
                        2022年7月，路⼈王和抖⾳联合官宣，抖⾳成为路⼈王2022赛季赛事直播独家合作伙伴；路⼈王抖⾳官⽅号粉丝量达到800万，
                        是除NBA之外第⼆个达到此规模的篮球内容IP；<br/>
                        2023年7月-8月，重启海外赛事，中日交流赛开启，引起全网热议；<br/>
                        2024年1月4日-5日，路人王2023年度总决赛开启，多项数据刷新纪录。1月4日首日比赛直播总观看人数达到1225.2万人，刷新路人王篮球抖音单场观看人数纪录。
                    </div>
                    <div className="row"><b>赛事体系</b></div>
                    <div className="row"><b>常规赛</b></div>
                    <div className="row">
                        极限竞技场：⾯向全国⼴⼤篮球爱好者，遍布全国130+座城市，站点冠军直通城市赛且是进⼊城市赛的唯⼀通道。<br/>
                        城市冠军赛：城市冠军赛参赛球员的每场对阵都将产⽣积分，积分榜前24名将直通年度总决赛。<br/>
                        年度总决赛：路人王一年一度的年度总决赛。
                    </div>
                    <div className="row"><b>品牌定制赛</b></div>
                    <div className="row">
                        校园奔袭赛：比赛时间为1天，场地选择在各大高校。打造全国⼤学⽣都能参与的校园篮球赛事。<br/>
                        女神赛：比赛阵容主要以邀请为主，汇集当下民间篮球圈实力与人气头部的女篮球选手，赛制安排兼顾比赛竞技和娱乐性。<br/>
                        厂牌争霸赛：打造国内知名篮球⼚牌热⾎对抗赛事。<br/>
                        精英制霸赛：比赛阵容以邀请为主，汇集当下民间篮球圈实力与影响力位于头部的球员。<br/>
                        Hoops Comes Home：路人王北美赛事是第一个进入海外市场的中国本土原创IP草根赛事。
                    </div>
                    <div className="row"><b>路人王青少年篮球赛</b></div>
                    <div className="row">
                        路人王参赛群体最年轻的赛事线。整体赛事线参赛年龄范围为8岁~18岁。
                    </div>
                    <div className="row"><b>赛季积分榜介绍</b></div>
                    <div className="row">
                        赛季积分榜根据路人王城市冠军赛所有参赛选手获得的赛季积分排名。<br/>
                        积分分配规则为单站冠军15分、亚军8分、季军5分、殿军3分、第五六名2分、第七八名1分。<br/>
                        城市冠军赛正赛、精英制霸赛、年度总决赛依据赛季积分榜进行球员选拔。
                    </div>
                    <div className="row"><b>路人王球员：</b></div>
                    <div className="row">
                        周锐 174CM／73KG | 路人王16冠王，2021年度总冠军，“武汉街球王”。<br/>
                        白晶194CM／84KG | 路人王41冠王，2022年度总冠军，“冷面刺客”。<br/>
                        孟亚东191CM／88KG | 路人王25冠王，2020年度总决赛殿军，“平常心”。<br/>
                        卢少敏 172CM／60KG | 路人王2023赛季洛阳站第5名，“用力的奶”。<br/>
                        黄一鸣 185CM/78KG | 路人王7冠王，前武汉体育学院男篮队长。<br/>
                        杨芸龙 182CM/75KG | 路人王城市赛两冠王，“外卡”球员城市赛正赛夺冠第一人。<br/>
                        王贺 178CM/ 70KG | 路人王2021、23赛季城市站两次第5名。<br/>
                        闫帅  183CM/85KG | 路人王城市赛两冠王，“YESER”。<br/>
                        孔琪 174CM/68KG | 路人王2020赛季武汉站第5名，“放松滴阿琪“。<br/>
                        蔡明峰 178CM/70KG | 路人王2023赛季洛阳站第7名，“小峰”。<br/>
                        付晓坡 183CM/80KG | 路人王2023赛季东莞站第7名，“密云直升机”。
                    </div>
                    <div className="title">合作伙伴</div>
                    <img src="https://w4.lurenwang.com/upload/rule/e817110d4a28481cac0fb8b7982c73bd.jpg"
                         className="partner"
                         alt="partner"/>
                    <div className="title">联系我们</div>
                    <div className="detail">赞助及商务合作：lrwbd@lurenwang.com</div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
