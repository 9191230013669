import {
  getGameList3,
} from "../../api/pc";
import { getQuery } from "../../utils";
import { get_live_page_url } from "../../utils/request";

/**
 * 是否当前时间有直播比赛
 */
export const get_live_game = async () => {
  if (getQuery("has_live") === "1")
    return {
      has_live_game: true,
      url: get_live_page_url("1067"),
      game_id: "1067",
    };
  let res2;

  res2 = await getGameList3();
  for (let item of res2["result"]) {
    if (
      item["hasLive"] === "Y" &&
      item["roomInfo"] &&
      item["roomInfo"]["isLive"] === 1
    ) {
      return {
        has_live_game: true,
        url: get_live_page_url(item.matchId,item["roomInfo"]['roomId']),
        game_id: String(item.matchId),
      };
    }
  }
  return {
    has_live_game: false,
    url: "",
    game_id: "",
  };
};


