export const game_list = {
  code: 200,
  message: "成功!",
  result: [
    {
      matchType: "city_customization_competition",
      matchName: "创建定制赛 删除外卡分组",
      matchId: 5260,
      startTime: "2021-09-12 20:35:06",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试-4",
      matchId: 5287,
      startTime: "2021-09-13 13:01:20",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "比赛结束测试",
      matchId: 5296,
      startTime: "2021-09-13 17:05:49",
      matchState: "has_ended",
      matchStateText: "已结束",
      hasLive: "Y",
      videoInfo: {
        vid: "4C9673DB11DC871E804E8FA68F914617",
        h5Url: "https://api-v1-sit.lurenwang.com/m/video/4C9673DB11DC871E804E8FA68F914617"
      }
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试城市冠军赛",
      matchId: 5302,
      startTime: "2021-09-14 10:03:00",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试开赛时间",
      matchId: 5304,
      startTime: "2021-09-14 10:36:24",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "2021城市冠军赛-测试站",
      matchId: 5306,
      startTime: "2021-09-14 17:00:05",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "2021赛季城市冠军赛-测试2",
      matchId: 5310,
      startTime: "2021-09-15 16:51:20",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "2021赛季城市冠军赛-鲁家齐测试",
      matchId: 5312,
      startTime: "2021-09-15 17:30:56",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "城市冠军赛-915测试",
      matchId: 5315,
      startTime: "2021-09-16 17:45:44",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "城市冠军赛-测试222",
      matchId: 5317,
      startTime: "2021-09-16 18:47:16",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "城市冠军赛-上海站",
      matchId: 5300,
      startTime: "2021-09-16 21:56:10",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试-3",
      matchId: 5276,
      startTime: "2021-09-17 14:07:02",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试啊啊",
      matchId: 5280,
      startTime: "2021-09-17 14:46:34",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "城市赛测试916",
      matchId: 5319,
      startTime: "2021-09-17 21:53:20",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_customization_competition",
      matchName: "测试创建比赛",
      matchId: 5106,
      startTime: "2021-09-19 18:00:00",
      matchState: "has_ended",
      matchStateText: "已结束",
      hasLive: "N"
    },
    {
      matchType: "city_customization_competition",
      matchName: "测试创建比赛",
      matchId: 5107,
      startTime: "2021-09-19 18:00:00",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "补位二维码测试-917",
      matchId: 5389,
      startTime: "2021-09-19 20:52:21",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试创建比赛0619",
      matchId: 5122,
      startTime: "2021-09-20 18:00:00",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试创建比赛11231",
      matchId: 5147,
      startTime: "2021-09-20 18:00:00",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "编辑创建比赛",
      matchId: 5103,
      startTime: "2021-09-20 18:00:00",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试创建比赛11231",
      matchId: 5110,
      startTime: "2021-09-20 18:00:00",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试界面",
      matchId: 5323,
      startTime: "2021-09-21 10:55:45",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "N"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "927直播测试",
      matchId: 5593,
      startTime: "2021-09-27 16:00:36",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y",
      roomInfo: {
        roomId: "11091",
        roomName: "测试直播间",
        cover: "https://w4.hoopchina.com.cn/39/c5/cc/39c5ccf75ac6164eb472b63d99192610001.jpg",
        online: 1,
        tips: "测试用",
        isLive: 1,
        allowRecord: 1,
        status: 0,
        h5Url: "https://api-v1-sit.lurenwang.com/m/room/11091"
      }
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "测试球员报名筛选",
      matchId: 5595,
      startTime: "2021-09-27 16:40:30",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y"
    },
    {
      matchType: "city_championship_zhengsai",
      matchName: "922测试站-城市冠军赛1111",
      matchId: 5586,
      startTime: "2021-09-28 14:42:45",
      matchState: "to_be_start",
      matchStateText: "待开赛",
      hasLive: "Y",
      roomInfo: {
        roomId: "11091",
        roomName: "测试直播间",
        cover: "https://w4.hoopchina.com.cn/39/c5/cc/39c5ccf75ac6164eb472b63d99192610001.jpg",
        online: 1,
        tips: "测试用",
        isLive: 1,
        allowRecord: 1,
        status: 0,
        h5Url: "https://api-v1-sit.lurenwang.com/m/room/11091"
      }
    }
  ]
};
