import React, {Component,} from 'react';
import Nav from '../../component/navigation';
import CopyRight from '../../component/copyRIght';
import './index.scss'
import banner from './img/banner.png'
import about from './img/about.png'
import banner_text from './img/banner_text.png'
import card1 from './img/card1.png'
import player from './img/player.png'
import success from './img/success.png'
import adidas from './img/adidas.png'
import xtep from './img/xtep.png'
import TRANSMISSION from './img/TRANSMISSION.png'
import matrix from './img/matrix.png'
import brand from './img/brand.png'
import brands from './img/brands.png'
class Index extends Component {
  /**
   * [render description]
   * @return {[type]} [description]
   */


  componentDidMount() {
  }

  render() {
    return (
      <div className='introductionPage'>
        <Nav active={3}/>
        <div className="banner">
          <img src={banner}/>
          <div className="content">
            <img src={about} style={{height: '64px', width: 'auto', marginTop: '50px'}}/>
            <img src={banner_text}/>
            {/* <p>We look abroad to explore the real landscape of folk basketball in all corners of the world, and  create a stage of 1V1 for basketball fans. While appreciating the basketball culture in all parts of the world, we spread the  rules and ideas of the King of Underdogs, and expect a more extreme individual heroism.  We determine to spread the footprint of the King of Underdogs all over the world and attract different folk basketball players to step on this stage. We devote to creating unique 1V1 basketball games and concepts with the identity of Chinese, and promote the  development of folk basketball in the whole world.  'Naismith invented basketball, but it was the streets of America that really gave basketball its soul.’ So we came to the  United States, which is the birthplace of basketball, and went to the west coast of Los Angeles, a city with two NBA teams  and deep basketball culture.</p> */}
          </div>
        </div>
        <main>
          <div className="card-1">
            <img src={card1} className="card" />
            <img src={player} className="player"/>
          </div>

          <img src={success} style={{height: '136px', width: 'auto', margin: '135px 0 102px'}}/>
          <img src={adidas} style={{ marginBottom: '24px'}}/>
          <img src={xtep}/>

          <img src={TRANSMISSION} style={{height: '136px', width: 'auto', margin: '204px 0 40px'}}/>
          <img src={matrix} />

          <img src={brand} style={{height: '64px', width: 'auto',  margin: '191px 0 123px'}}/>
          <div className="brands">
            <img src={brands}/>
            
          </div>
        </main>
        <CopyRight active={3}/>
      </div>

    );
  }
}


export default Index;