import { requestJava, service } from "../utils/request";
import { player_list_java_data } from "./player_list_java";
import { getQuery } from "../utils";

function toLine(obj) {
  for (let prop in obj) {
    let line_name = prop.replace(/([A-Z])/g, "_$1").toLowerCase();
    obj[line_name] = obj[prop];
    delete obj[prop];
  }
  return obj;
}
const getNewsList = (params) => {
  return requestJava(`/common/api/cms/content/getList`, {
    method: "get",
  });
  // return service(`/officialApi/newMation`, {
  //   method: "get",
  //   params,
  // });
};

const getNewsDetail = (params) => {
  // https://api.lurenwang.com/common/api/cms/content/get?id=1422857664710438914
  // article_id
  return requestJava(`/common/api/cms/content/get?id=${params["article_id"]}`, {
    method: "get",
  });
  // return service(`/officialApi/inforMationDetails`, {
  //   method: "get",
  //   params,
  // });
};

const getAllNews = async (params) => {
  // https://api.lurenwang.com/common/api/cms/content/getList?current=1&size=20
  return requestJava(
    `/common/api/cms/content/getList?current=${params["page"]}&size=${params["limit"]}`,
    {
      method: "get",
    }
  );
};
/**
 * 默认视频列表
 */
const getVideoList = (params) => {
  return requestJava(`/live/api/video/list`, {
    method: "get",
    params,
  });
};
/**
 * 搜索视频列表
 */
const getSearchVideoList = (params) => {
  return requestJava(`/live/api/video/list`, {
    method: "get",
    params,
  });
};
/**
 *  直播stream name 接口
 */
const getLiveStream = (room_id, params) => {
  return service(`/miniprogram/room/${room_id}`, {
    method: "get",
    params,
  });
};

const getGameList = (params) => {
  // https://www-sit.liangle.com/m/game/list
  //todo 日期使用动态计算 最近两天范围
  return service(`/api/passerbyking/game/daylist?start=20210701&end=20211201`, {
    method: "get",
    params,
  });
};
export const getVideoPlayUrl = (vid) => {
  // return service(`/api/pbk/videoInfo`, {
  //   method: "get",
  //   params,
  // });
  return requestJava(`/live/api/video/${vid}`, {
    method: "get",
  });
};
const getGameList2 = (params) => {
  // https://www-sit.liangle.com/api/pbk/game/allList
  return service(`/api/pbk/game/allList`, {
    method: "get",
    params,
  });
};

const getGameList3 = () => {
  return requestJava(`/common/api/match/h5/getMatchList`, {
    method: "get",
  });
};

export const getGamePlayerList2 = (game_id) => {
  // https://www-sit.liangle.com/api/pbk/game/allList
  return service(`/api/passerbyking/gameplayer/list/${game_id}?group_match=1`, {
    method: "get",
  });
};
//海外赛事官网
export const getHaiwWaiSpwipe = () => {
  // www.liangle.com/m/abroad/rotation/list
  return service(`/m/abroad/rotation/list`, {
    method: "get",
  });
};
/**
 *视频列表
 */
export const getHaiwWaiVideo = () => {
  // www.liangle.com/m/abroad/rotation/list
  return service(`/m/abroad/recommend/list`, {
    method: "get",
  });
};

export const getOnlineCount = (room_id) => {
  return requestJava(`/live/api/room/online/${room_id}`, {
    method: "get",
  });
};
// 在liangle。com调用的接口
const getGamePlayerList = (game_id) => {
  return service(`/api/passerbyking/gameplayer/list/${game_id}?group_match=1`, {
    method: "get",
  });
};
export const getGamePlayerListJava = (matchId) => {
  //https://api-sit.lurenwang.com/common/api/match/h5/getPlayerList?matchId=1234
  if (getQuery("test_player") === "1") {
    return player_list_java_data;
  }
  return requestJava(`/common/api/match/h5/getPlayerList?matchId=${matchId}`, {
    method: "get",
  });
};
const getGameList_LL = () => {
  return service(`/api/passerbyking/game/daylist?start=20210701&end=20211201`, {
    method: "get",
  });
};

export const getGameList_LL2 = () => {
  return service(`/api/pbk/game/allList`, {
    method: "get",
  });
};

export const sendMobileCode = (mobile) => {
  return requestJava(`/common/api/sms/sendMobileCode`, {
    method: "POST",
    data: {
      mobile,
      bizScene: "SMS_LOGIN"
    }
  });
};

export const checkImgCode = (data) => {
  return requestJava(`/common/api/captcha/verify`, {
    method: "POST",
    data
  });
};

export const getImgCode = () => {
  return requestJava(`/common/api/captcha/imageCode`, {
    method: "GET",
  });
}

export const userLogin = (data) => {
  console.log(data, 'usuerReq');
  return requestJava(`/common/api/user/login`, {
    method: "POST",
    data
  });
};

export const getUserInfo = () => {
  return requestJava(`common/api/user/getUserInfo`, {
    method: "get"
  });
};

const queryAllBanner = (matchId) => {
  //获取banner图片
  requestJava.defaults.headers["os-platform"]='web'
  return requestJava({
      url:`/common/api/banner/queryAllBanner`,
      method: "get",
      header: {
        "os-platform": 'web'
      },
      params:{
        outBizType: "OFFICE_WEB"
      }
  });
};

export {
  getNewsList,
  getNewsDetail,
  getAllNews,
  getVideoList,
  getGameList,
  getLiveStream,
  getGameList_LL,
  getGameList2,
  getGameList3,
  getGamePlayerList,
  getSearchVideoList,
  queryAllBanner,
};
