// import DPlayer from "react-dplayer";
import DPlayer from "dplayer";
import React, {useCallback, useEffect, useState} from "react";
import {Col, Container, Row, setConfiguration} from "react-grid-system";
import Script from "react-load-script";
import {
    getOnlineCount,
    getGamePlayerListJava,
} from "../../api/pc";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {getQuery, useWillSize} from "../../utils";
import bg from "./img/bg.png";
import eye from "./img/eye.png";
import "./style.scss";

let http_ = window.location.protocol;
let room_id = "10318";
room_id = "10977"; //测试环境测试直播间

let href = window.location.href;

let hls_url = `${http_}//live.lurenwang.com/prod/MdI6rQzyUQ6rEAkC.m3u8`; // 10975
if (href.includes("sit")) {
    hls_url = `${http_}//live.lurenwang.com/test/dGdtRHM3c3l6ZnFY.m3u8`; // 11091
    console.log(
        "测试环境直播地址 http://api-v1-sit.lurenwang.com/flow/stream?room_id=11091 :>> ",
        hls_url
    );
}
const LivePlayer = ({viewer}) => {
    const win_size = useWillSize();
    //
    const on_hls_load = (e) => {
        console.log("hls loaded :>> ");
        set_is_hls_load(true);
        const dp = new DPlayer({
            container: document.getElementById("dplayer"),
            autoplay: true,
            lang: "zh-cn",
            video: {
                url: hls_url,
                type: "hls",
            },
            // quality: [
            //   {
            //     name: "超清",
            //     url: "demo.m3u8",
            //     type: "hls",
            //   },
            //   {
            //     name: "高清",
            //     url: "demo.mp4",
            //     type: "hls",
            //   },
            //   {
            //     name: "普清",
            //     url: "demo.mp4",
            //     type: "hls",
            //   },
            // ],
            // defaultQuality: 0,
            // 0:超清 1:高清 2:普清
            contextmenu: [
                // {
                //   text: "超清",
                //   click: () => {
                //     on_rate(0);
                //   },
                // },
                // {
                //   text: "高清",
                //   click: () => {
                //     on_rate(1);
                //   },
                // },
                // {
                //   text: "普清",
                //   click: () => {
                //     on_rate(2);
                //   },
                // },
            ],
        });
    };
    //
    const [is_hls_load, set_is_hls_load] = useState(false);
    // useEffect
    useEffect(() => {
        (async () => {
            // let res = await getLiveStream(room_id);
            // let res = await fetch(api_live);
            // console.log("getLiveStream res :>> ", res);
        })();
    }, []);
    return (
        <>
            <Script
                url="https://cdn.bootcdn.net/ajax/libs/hls.js/1.0.8-0.canary.7646/hls.min.js"
                onLoad={on_hls_load.bind(this)}
            />
            {is_hls_load && (
                <div
                    className="player-container"
                    style={{
                        top: 20,
                        marginLeft: win_size.width * (1 - 0.5) * 0.5,
                        width: win_size.width * 0.5,
                    }}
                >
                    <div>
                      <div className="viewer"></div>
                      <div className="viewer-cont">
                        <img src={eye} className="viewer-eye" />
                        <span>{viewer}人在看</span>
                      </div>
                    </div>
                    <div
                        id="dplayer"
                        style={{
                            marginTop: 20,
                        }}
                    ></div>
                </div>
            )}
        </>
    );
};
export const PlayerCard = ({player}) => {
    return (
        <div className="player-card">
            <Container>
                <Row>
                    <Col sm={14}>
                        <Row>
                            <Col sm={8}>
                                <img src={player.headPortrait} className="player-header"></img>
                            </Col>
                            <Col sm={16}>
                                <div className="player-name">{player.name}</div>
                                <div className="player-title">{player.title}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={10}>
            <span className="player-info">
              {player.age}岁 {player.height}cm {player.weight}kg
            </span>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default function LiveRoom({history}) {
    const [state, setState] = useState({
        game_id: "1305",
        group_arr: [],
        viewer: "",
    });
    setConfiguration({
        defaultScreenClass: "sm",
        gutterWidth: 0,
        gridColumns: 24,
    });

    const [group_arr, set_group_arr] = useState([]);
    const group_name = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    const get_player_list = async () => {
        let game_id = getQuery("game_id");
        if (game_id) {
            setState({...state, game_id});
        } else {
            game_id = state.game_id;
        }
        console.log("球员列表 刷新开始 :>> ", game_id);
        let res = await getGamePlayerListJava(game_id);
        let c = res["result"]["playerList"];
        console.log("球员列表 刷新数据 :>> ", c);
        let a = [];
        let all_player_arr = [];


        // for (let i=0; i<c.length; i + 3) {
        //   group_name.push((i / 3).toString());
        // }

        for (let group of group_name) {
            let player_arr = [];
            for (let i = 0; i < 3; i++) {
                if (c.length > 0) {
                    let p = c.shift();
                    console.log("球员：" + p.name);
                    let item = {group, player: p};
                    player_arr.push(item);
                }
            }

            all_player_arr = [...all_player_arr, ...player_arr];
            if (player_arr.length > 0) {
                a.push({group, player_arr});
            }
        }
        set_group_arr(a);
    };
    const load_viewer = async () => {
        let room_id = getQuery("room_id");
        if (!room_id) {
            room_id = 10975;
        }
        let res = await getOnlineCount(room_id);
        if (res?.result?.onlineNumFormat) {
            let onlineNumFormat = res.result.onlineNumFormat;
            setState((state) => {
                return {...state, viewer: onlineNumFormat};
            });
        }
    };

    useEffect(() => {
        get_player_list();
        load_viewer();

        setInterval(async () => {
            //
            load_viewer();
            // console.log("res :>> ", res);
        }, 60000);
    }, []);
    return (
        <div>
            <Header location={history.location}/>
            <div className="player-section">
                <img className="live-bg" src={bg} alt="img-play"/>

                <LivePlayer viewer={state.viewer}/>
            </div>
            <div className="game-section">
                <Container>
                    <Row>
                        <Col sm={24}>
                            <span className="title">球员统计</span>
                        </Col>
                    </Row>
                    {group_arr.map((group, i) => (
                        <Row key={group.group}>
                            {group.group}
                            {group.player_arr.map((it) => (
                                <Col sm={8} key={it.player.name}>
                                    <div
                                        className={
                                            i % 2 === 1 ? "player-col-even" : "player-col-odd"
                                        }
                                    >
                                        <PlayerCard {...{player: it.player}}></PlayerCard>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Container>
            </div>
            <Footer/>
        </div>
    );
}
