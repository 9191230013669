import H5Iframe from "../../components/h5-iframe";
import { env_is_sit ,env_is_stg} from "../../utils/env";

export default function Rank({ history }) {
  
  // let url = env_is_sit() 
  // ? "https://h5-sit.lurenwang.com/h5/rank/1/0"
  // : env_is_stg() 
  // ? "https://h5-stg.lurenwang.com/h5/rank/1/0"
  // : "https://h5.lurenwang.com/h5/rank/1/0";
  let url = env_is_sit() 
  ? "https://mini-sit.lurenwang.com/#/pages/rank/list"
  : env_is_stg() 
  ? "https://mini-stg.lurenwang.com/#/pages/rank/list"
  : "https://mini.lurenwang.com/#/pages/rank/list";
  let height = "1700px";
  return <H5Iframe {...{ history, url, height, scroll: "auto" }}></H5Iframe>;
}
