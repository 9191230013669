import Footer from "./Footer";
import Header from "./Header";
export default function H5Iframe({ history, url, height, scroll = "no" }) {
  //   let url = "http://m.liangle.com/h5/signtip";
  //   let height = "3080px";
  url = url.replace("http://", `${window.location.protocol}//`);
  return (
    <div>
      <Header location={history.location}></Header>
      <div
        className="h5-iframe"
        style={{
         width:'800px',
         margin: 'auto',
        }}
      >
        <iframe
          frameBorder="0"
          scrolling={scroll}
          //   scrolling="no"
          width="100%"
          height={height}
          src={url}
        />
      </div>
      <Footer />
    </div>
  );
}
