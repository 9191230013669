import React, { Component } from "react";
import "./index.scss";
import Nav from "../../component/navigation";
import Swiper from "./component/swiper";
import { withRouter } from "react-router-dom";
import Title from "../../component/title";
import Video from "./component/video";
import CopyRight from "../../component/copyRIght";
import { getHaiwWaiSpwipe, getHaiwWaiVideo } from "../../../../api/pc";
// import api from '../../api/api'
import highlights from './icon/highlights.png'
class Index extends Component {
  /**
   * [render description]
   * @return {[type]} [description]
   */

  state = {
    swiperList: [
      {
      id: "1",
      title: "Bone Collector is coming! ",
      url: "https://www.youtube.com/watch?v=45nYea9YpPY",
      img: "https://w4.lurenwang.com/upload/source/db71d11c8697472489765cf4f234cce5.webp",
      create_time: "2020/04/03"
      },
      {
      id: "3",
      title: "Hoops comes home in LA",
      url: "https://www.youtube.com/watch?v=A8hsXMQ3EbA&t=",
      img: "https://w2.lurenwang.com/upload/source/d1fd803f023243d7a82c04d1a48eac56.webp",
      create_time: "2020/04/03"
      },
      {
      id: "2",
      title: "Hoops Comes Home -- Rule Introduction",
      url: "https://www.youtube.com/watch?v=1ejixeDjdME",
      img: "https://w1.lurenwang.com/upload/source/969801f470d744b489bad94c5139ed47.png",
      create_time: "2020/04/03"
      }
  ],
    videoList: [
      {
      id: "4",
      title: "Champion Highlights of Hoops Comes Home in Houston",
      img: "https://w4.lurenwang.com/upload/source/29eacddb94ad4f6aa334129df40b805a.webp",
      url: "https://www.youtube.com/watch?v=zL3XQQszwZ0&t",
      create_time: "2019/12/09"
      },
      {
      id: "3",
      title: "Highlights of Hoops Comes Home in San Francisco(1)",
      img: "https://w4.lurenwang.com/upload/source/42a023a2083e4470b9fe0ca491e5b70a.webp",
      url: "https://www.youtube.com/watch?v=Ednua0RIp-s&t",
      create_time: "2019/12/10"
      },
      {
      id: "2",
      title: "Highlights of Hoops Comes Home in San Francisco(2)",
      img: "https://w2.lurenwang.com/upload/source/7b4a6222820b41638a5c6d28fabc72df.webp",
      url: "https://www.youtube.com/watch?v=XBBg37uJbYI",
      create_time: "2019/12/10"
      },
      {
      id: "1",
      title: "Fancy moves—highlights of Sean Hill from Chicago",
      img: "https://w4.lurenwang.com/upload/source/35dc611cca7c41bf9e06ab15956d0a7f.webp",
      url: "https://www.youtube.com/watch?v=Bpa2satF7z0&t",
      create_time: "2019/12/09"
      }
      ],
  };

  componentDidMount() {
    // sa.track('LrwOverseasHomePage_W', {
    //   platform: 'PC',
    // });

    // api.getSwiperList().then(data => {
    //   this.setState({
    //     swiperList: data.data
    //   })
    // });

    // getHaiwWaiSpwipe().then((data) => {
    //   // console.log("getHaiwWaiSpwipe :>> ", data);
    //   this.setState({
    //     swiperList: data.data,
    //   });
    // });
    // getHaiwWaiVideo().then((data) => {
    //   this.setState({
    //     videoList: data.data,
    //   });
    // });
    // api.getVideoList().then(data => {
    //   this.setState({
    //     videoList: data.data
    //   })
    // });
  }

  render() {
    const { swiperList, videoList } = this.state;

    return (
      <div className="homePage">
        <Nav />
        {swiperList.length > 0 && <Swiper swiperList={swiperList} />}
        <main>
          {/* <Title title={'HIGHLIGHTS'}/> */}
          <img src={highlights} className="highlights" />

          <div className="video-list">
            {videoList &&
              videoList.length > 0 &&
              videoList.map((video, index) => (
                <div className="video-item" key={video.id}>
                  <Video id={index} video={video} />
                </div>
              ))}
          </div>
        </main>

        <CopyRight />
      </div>
    );
  }
}

// export default withRouter(Index);
export default Index;
