import React, {useState, useEffect} from 'react'
import SwiperCore, {Pagination, Navigation, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Link} from "react-router-dom";

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {douyin, kuaishou, game} from './img/imgs'
import {Bilibili, Dewu, Ins, Shipinhao, Tiktok, Wechat, Weibo, Weishi, UC} from './img/partners/partners'
import {Banner0, Banner1, Banner2} from './img/banner/imgs'
import {getNewsList, queryAllBanner} from '../../api/pc'

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import './style.scss'


SwiperCore.use([Navigation, Pagination, Autoplay]);

function Home({history}) {
    const [swiperList, setList] = useState([]);
    const [supSwiper, setSupSwiper] = useState({});
    const [newsList, setNews] = useState([]);

    console.log(history, 'history');
    const cutContent = (ctx) => {
        console.log(ctx, 'ctx');
        if (!ctx) return ''
        if (ctx.length > 40) {
            return ctx.slice(0, 40) + '...'
        } else {
            return ctx
        }
    }

    const goToNewsDetail = (id) => {
        history.push(`/news/${id}`)
    }

    useEffect(() => {
        const fetchData = async () => {
            const result = await getNewsList()
            setNews(result.data.slice(0, 4))
            console.log(result, '2333');
        }
        fetchData();
        console.log('执行了')
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            let bannerResult = await queryAllBanner()
            setList(bannerResult.result.banners || [])
        }
        fetchData();
        console.log('执行了')
    }, [])
    return (
        <div className="home-container">
            <Header location={history.location}/>
            <Swiper
                slidesPerView={1}
                loop={true}
                navigation
                autoplay={{
                    "delay": 10000,
                    "disableOnInteraction": false
                }}
                pagination={{clickable: true}}
                onSlideChange={() => console.log('slide change')}
            >
                {
                    swiperList.map((img, idx) =>
                        img.h5Url ? (
                            <SwiperSlide key={idx}>
                                <div className="img-tab" onClick={() => window.open(img.h5Url)}>
                                    <img src={img.imgUrl} alt=""/>
                                </div>
                            </SwiperSlide>
                        ) : (
                            <SwiperSlide key={idx}>
                                <div className="img-tab">
                                    <img src={img.imgUrl} alt=""/>
                                </div>
                            </SwiperSlide>
                        )
                    )
                }
            </Swiper>
            <div className="video-block">
                <div className="douyin">
                    <div className="des"/>
                    <img src={douyin} className="logo" alt="douyin"/>
                    <div className="text">
                        <div>● 第二大篮球IP 体育类官方账号影响力TOP3；</div>
                        <div>● 官方账号视频点赞量2.6亿， 篮球类TOP2；</div>
                        <div>● 签约球员&MCN合作达人粉丝数2500万+；</div>
                    </div>
                    <div className="text">
                        <div>● 荣获2020年抖音最具影响力赛事称号；</div>
                        <div>● 2021年度总决赛总观看人次近千万；</div>
                        <div>● 巨量算数抖音指数峰值156万；</div>
                    </div>
                </div>
                <div className="kuaishou">
                    <div className="des"/>
                    <img src={kuaishou} className="logo" alt="douyin"/>
                    <div className="text">
                        <div>● 快手第二大篮球IP、篮球类MCN机构</div>
                        <div>● 亿级单月官方账号视频播放量</div>
                        <div>● 2019年度快手体育达人</div>
                    </div>
                </div>
            </div>
            <div className="supports">
                <Swiper
                    slidesPerView={8}
                    slidesPerGroup={8}
                    spaceBetween={20}
                    watchOverflow={true}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => setSupSwiper(swiper)}
                    autoplay={{
                        "delay": 10000,
                        "disableOnInteraction": false
                    }}
                >
                    <SwiperSlide>
                        <div className="support"
                             onClick={() => window.location.href = "https://weibo.com/huputvlive?is_all=1"}><img
                            src={Weibo} alt="weibo"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support wx">
                            <div className="bubble">
                                <div className="qr"/>
                            </div>
                            <img src={Wechat} alt="wechat"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support"
                             onClick={() => window.location.href = "https://space.bilibili.com/20391398"}><img
                            src={Bilibili} alt="bilibili"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support dewu">
                            <div className="bubble">
                                <div className="qr"/>
                            </div>
                            <img src={Dewu} alt="dewu"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support shipinhao">
                            <div className="bubble">
                                <div className="qr"/>
                            </div>
                            <img src={Shipinhao} alt="shipinhao"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support"><img src={Weishi} alt="weishi"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support uc" onClick={() => window.location.href = "https://mparticle.uc.cn/media.html?spm=a2s5w.13822044.13822022.7.2b4465faHMceF5&mid=99bb0fdb457042da8ab27c7ffa1909af&client=ucweb&uc_param_str=frdnsnpfvecpntnwprdss&uc_biz_str=S:custom|C:iflow_ncmt"}>
                            <div className="bubble">
                                <div className="qr"/>
                            </div>
                            <img src={UC} alt="UC大鱼号"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support"><img src={Tiktok} alt="tiktok"/></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="support"><img src={Ins} alt="ins"/></div>
                    </SwiperSlide>
                </Swiper>
                {/* <div className="prev-btn" onClick={() => supSwiper.slidePrev()}/>
        <div className="next-btn" onClick={() => supSwiper.slideNext()}/> */}
            </div>
            <div className="board">
                <div className="news">
                    <div className="title">
                        <div className="txt">新闻资讯</div>
                        <Link className="more" to="/newslist">more ></Link>
                    </div>
                    <div className="news-content">
                        {
                            newsList.map(n => (
                                <div className="news-block" key={n.id} onClick={() => goToNewsDetail(n.id)}>
                                    <div className="img"><img src={`${n.contentImg}`} alt="news"/></div>
                                    <div className="news-title"><span
                                        className="column">【{n.categoryTitle}】</span>{n.contentTitle}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home