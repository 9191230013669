import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./img/logo.png";
import classNames from "classnames";
import "./style.scss";
import { 
  get_live_game, 
} from "./get-live-game";
import {
  // 登录相关
  sendMobileCode,
  checkImgCode,
  getUserInfo,
  userLogin, getImgCode

} from "../../api/pc";
import { Container, Row, Col, setConfiguration } from "react-grid-system";
import { env_is_sit, env_is_stg } from "./../../utils/env";
import { Modal, Button, Form, Input, Checkbox, Row as AntdRow, Col as AntdCol, Divider } from 'antd';
import { requestJava } from '../../utils/request';

import 'antd/dist/antd.css';

const Link2 = ({
  path,
  router_path,
  to_url,
  title,
  active = true,
  children = null,
}) => {
  const is_on_liangle = window.location.href.includes("liangle.com");
  const is_newlive_sit = window.location.href.includes("sit");
  const liangle_to_url = is_newlive_sit
    ? "https://www-sit.lurenwang.com/#" + router_path
    : env_is_stg()
    ? "https://www-stg.lurenwang.com/#" + router_path
    : "https://www.lurenwang.com/#" + router_path;
  return (
    <>
      {is_on_liangle ? (
        <a href={liangle_to_url} target="_blank" rel="noreferrer">
          {title}
          {children && children}
        </a>
      ) : (
        <Link
          to={router_path}
          className={classNames({
            "active-tab": active && path === router_path,
          })}
        >
          {title}
          {children && children}
        </Link>
      )}
    </>
  );
};
export default function Header({ location }) {
  setConfiguration({ defaultScreenClass: "sm", gridColumns: 24 });
  const path = location ? location.pathname : "";
  const [live_game, set_live_game] = useState({
    has_live_game: false,
    url: "",
    game_id: "",
  });

  const [need_login, set_need_login] = useState(!localStorage.getItem('lrw-token'));
  const [show_login, set_show_login] = useState(false);
  const [code_img, set_code_img] = useState('');
  const [img_code, set_img_code] = useState('')
  const [mobile, set_mobile] = useState('')
  const [mobile_code, set_mobile_code] = useState('')
  let [counter, set_counter] = useState('');
  let [count, set_count] = useState(0)
  const [user, set_user] = useState({})
  const [uuid, set_uuid] = useState('')


  useEffect(() => {
    set_counter(setTimeout(() => {
      if (count > 0) {
        set_count((c) => --c); // ✅ 在这不依赖于外部的 `count` 变量
      }
    }, 1000))
  }, [count]);

  useEffect(() => {
    (async () => {
      set_live_game(await get_live_game());
      setInterval(async () => {
        set_live_game(await get_live_game());
      }, 60 * 1000);
    })();

    if (localStorage.getItem('lrw-token')) {
      get_user_info()
    }

    (async () => {
      setCodeImg()
    })();


  }, []);
  const hurl = env_is_sit()
    ? "https://www-sit.lurenwang.com/#/hoopscomeshome/"
    : env_is_stg()
    ? "https://www-stg.lurenwang.com/#/hoopscomeshome/"
    : "https://www.lurenwang.com/#/hoopscomeshome/";

    
  const setCodeImg = async () => {
    const res = await getImgCode();

    set_code_img(res.result.imgData);
    set_uuid(res.result.uuid);
  }

  const get_user_info = async () => {
    const res = await getUserInfo()
    set_user(res.result)
    set_need_login(!localStorage.getItem('lrw-token'))
    set_show_login(!localStorage.getItem('lrw-token'))
  }

  const img_code_change = (e) => {
    set_img_code(e.target.value)
  }

  const mobile_change = (e) => {
    set_mobile(e.target.value)
  }

  const mobile_code_change = (e) => {
    set_mobile_code(e.target.value)
  }

  const checkCodeAndSend =  async () => {
    const res1 = await checkImgCode({
      code: img_code,
      uuid: uuid
    });
    if (res1.result.success === 'Y') {
      if (mobile === '') {
        return
      }

      const res2 = await sendMobileCode(mobile);
      set_count(res2.result.expire)
    }
  }

  const toLogin = async () => {
    const req = {
      code: mobile_code,
      loginType: "MOBILE_CODE",
      mobile: mobile,
    }
    const res = await userLogin(req)
    localStorage.setItem('lrw-token', res.result.token)
    localStorage.setItem('lrw-user-id', res.result.userId)
    requestJava.defaults.headers['Authorization'] = res.result.token
    requestJava.defaults.headers['lrw-user-id'] = res.result.userId
    get_user_info()
  }

  const logout = () => {
    localStorage.removeItem("lrw-token")
    localStorage.removeItem("lrw-user-id")
    requestJava.defaults.headers['Authorization'] = ''
    requestJava.defaults.headers['lrw-user-id'] = ''
    set_need_login(true)
  }

  return (
    <div className="header-container">
      <div className="top-header">您好，欢迎来到路人王！</div>
      <Container style={{ maxWidth: 3000 }}>
        <Row justify="end">
          <Col sm={5.5}>
            <nav className='nav2'>
              <img src={Logo} alt="logo" className="logo2" />
            </nav>
          </Col>
          <Col>
            <ul className="cols">
              <Link2
                {...{ title: "首页", path, router_path: "/", to_url: "" }}
              />
              <Link2
                {...{
                  title: "报名参赛",
                  path,
                  router_path: "/signtip",
                  to_url: "",
                }}
              >
                <div className="hot icon" />
              </Link2>
              {live_game.has_live_game ? (
                <a href={live_game.url} target="_blank" rel="noreferrer" className={classNames({ "active-tab": path === "/live" })}>
                  赛事直播
                  <div className="red-icon" />
                </a>
              ) : (
                <Link2
                  {...{
                    title: "赛事直播",
                    path,
                    router_path: "/gamelist", //没直播的时候跳近期赛程
                    to_url: "",
                    active: false,
                  }}
                />
              )}

              <Link2
                {...{
                  title: "近期赛程",
                  path,
                  router_path: "/gamelist",
                  to_url: "",
                }}
              />
              <Link2
                {...{
                  title: "比赛视频",
                  path,
                  router_path: "/videolist",
                  to_url: "",
                }}
              />
              <Link2
                {...{
                  title: "排行榜",
                  path,
                  router_path: "/rank",
                  to_url: "",
                }}
              />
              <Link2
                {...{
                  title: "签约球星",
                  path,
                  router_path: "/signplayers",
                  to_url: "",
                }}
              />
              <a
                href="https://forms.lurenwang.com/f/WKVP2u"
                target="_blank"
                rel="noreferrer"
              >
                赛事申诉
              </a>
              <Link2
                {...{
                  title: "关于我们",
                  path,
                  router_path: "/about",
                  to_url: "",
                }}
              />
              <a href={hurl} target="_blank" rel="noreferrer">
                海外赛
              </a>
              <a
                href="https://shop234039123.taobao.com/category.htm?spm=a1z10.1-c-s.w5001-22938568441.8.6c241d59jAk6I8&search=y&scene=taobao_shop"
                target="_blank"
                rel="noreferrer"
              >
                购买周边
                <div className="shop icon" />
              </a>
            </ul>
          </Col>
          <Col sm={4}>
            {need_login ? <div className="login-btns">
              <div className="login-btn" onClick={() => set_show_login(true)}>登录 / 注册</div>
            </div> : <div className="login-btns user-info">
              { user.userId ? <img class="avatar" src={user.headPortrait} />: <div></div>}
              { user.userId ?  <div className="login-btn user-name">{ user.nickName || user.realName}</div> : <div></div>}
              <div className="login-btn" onClick={logout}>退出</div>
            </div>}
          </Col>
        </Row>
      </Container>
      <Modal visible={show_login} onOk={toLogin} footer={null} width={440} onCancel={() => set_show_login(false)}>
        <div className="login-modal-header">- 使用手机号登录/注册 -</div>
        <Form
          name="basic"
          className="login-form"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入手机号!' }]}
          >
            <Input placeholder="请输入手机号"  onChange={mobile_change} value={mobile}/>
          </Form.Item>
          
          <Row >
            <Col sm={14}>
              <Form.Item
                rules={[{ required: true, message: '请输入图片验证码' }]}
              >
                <Input placeholder="请输入图形验证码" onChange={img_code_change} value={img_code}/>
              </Form.Item>
            </Col>
            <Col sm={6}>
              <img className="code-img" src={code_img} onClick={setCodeImg}/>
            </Col>
            <Col sm={4}>
              <div style={{'color':'#BFBFBF',fontSize: '6px', 'cursor':'pointer'}} onClick={setCodeImg}>看不清</div>
              <div style={{'color': '#0562DF',fontSize:' 6px', 'cursor':'pointer'}}  onClick={setCodeImg}>换一张</div>
            </Col>
          </Row>
          <Row>
            <Col sm={14}>
              <Form.Item
                rules={[{ required: true, message: '请输入手机验证码' }]}
              >
                <Input className="login-input" placeholder="请输入手机验证码" onChange={mobile_code_change} value={mobile_code}/>
              </Form.Item>
            </Col>
            <Col sm={10}>
            <Button type="primary" htmlType="submit" className="submit-btn" onClick={checkCodeAndSend} disabled={count !== 0}>{count === 0 ? '获取验证码': `重新发送(${count}s)`}</Button>
            </Col>
          </Row>
          <Form.Item >
            <Button type="primary" htmlType="submit" className="submit-btn" onClick={toLogin}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
