export const player_list_java_data = {
  code: 200,
  message: "成功!",
  result: {
    matchId: 1234,
    playerList: [
      {
        playerId: 49411,
        playerCode: "g47dy",
        name: "黎钰友",
        resume: "只是一个热爱篮球的小伙子",
        headPortrait:
          "http://w4.hoopchina.com.cn/28/e3/f7/28e3f7ebb4f31968a9d5c354cfc1fa24001.jpg@194h_194w_2e",
        title: "神秘实力球员",
      },
      {
        playerId: 9405,
        playerCode: "v0dm5",
        name: "李宏亮",
        resume: "路人王东莞站冠军，2018年jump10中国队成员，原汕头大学校队队员",
        headPortrait:
          "http://w2.hoopchina.com.cn/a9/a4/39/a9a4391291ceda4796eea0ef1695d59a001.jpg@194h_194w_2e",
        title: "路人王东莞站冠军，前汕大球员",
      },
      {
        playerId: 102092,
        playerCode: "z7gje",
        name: "匡峥",
        resume:
          "大学期间，曾三次代表学校出去打比赛，在重庆参加多次3v3比赛，以及单挑赛！",
        headPortrait:
          "http://w1.hoopchina.com.cn/ac/3b/b2/ac3bb20835bc6a3df7ebd14926c2664f001.jpg@194h_194w_2e",
        title: "重庆城市管理学院校队球员",
      },
      {
        playerId: 9097,
        playerCode: "6eygp",
        name: "姚育勤",
        resume:
          "路人王广州海口两站冠军，九月冠军赛成员，小快灵球员，来自华南师范大学",
        headPortrait:
          "http://w2.hoopchina.com.cn/9d/94/61/9d94619e81916550e6d2099b67468985001.jpg@194h_194w_2e",
        title: "路人王两冠王，小飞机",
      },
      {
        playerId: 10207,
        playerCode: "n944j",
        name: "许涛",
        headPortrait:
          "http://w1.hoopchina.com.cn/9a/24/41/9a24413fd6a2e87f2cdeb549b7ee16ec001.jpg",
        title: "路人王3冠王，牛蛙人",
      },
      {
        playerId: 16994,
        playerCode: "wkx4y",
        name: "何博强",
        resume: "800米国家二级运动员",
        headPortrait:
          "http://w1.hoopchina.com.cn/70/b8/85/70b885a58abf1b8edcc88d0607dd59d9001.jpg@194h_194w_2e",
        title: "中山大学学生",
      },
      {
        playerId: 30566,
        playerCode: "oy4pr",
        name: "那尔太",
        resume: "华南理工大学校队球员，AUBA大学生联赛四强，路人王昆明站第四",
        headPortrait:
          "http://w2.hoopchina.com.cn/b7/be/d2/b7bed21b7c75da467d21297e466e8f94001.jpg@194h_194w_2e",
        title: "路人王广州站亚军，华南理工校队球员",
      },
      {
        playerId: 85629,
        playerCode: "0ro05",
        name: "林玮琛",
        resume: "路人王路人",
        headPortrait:
          "http://w1.hoopchina.com.cn/17/7c/03/177c03b487840d83121f379595c88087001.jpg@194h_194w_2e",
        title: "神秘实力球员",
      },
      {
        playerId: 134720,
        playerCode: "zg23z",
        name: "吴俊杰",
        resume: "销售",
        headPortrait:
          "https://w1.hoopchina.com.cn/images/lrw/mini_program/default_avatar.png",
        title: "",
      },
      {
        playerId: 19601,
        playerCode: "ep70j",
        name: "谢浩",
        headPortrait:
          "http://i1.hoopchina.com.cn/user/5f/eb/94/5feb94cc3a7cd080f4e15e9b8e6a6c16001.jpg@194h_194w_2e",
        title: "路人王4冠王，流浪球手",
      },
      {
        playerId: 106361,
        playerCode: "y71w0",
        name: "徐远征",
        resume:
          "美国NCAAD1大学篮球，香港代表队，2015年Nike亞洲营明星，现效力香港甲一球队。",
        headPortrait:
          "http://w2.hoopchina.com.cn/f4/28/ca/f428ca3db1bee76a786a7428adc12c45001.jpg@194h_194w_2e",
        title: "前莱斯大学校队球员",
      },
      {
        playerId: 113860,
        playerCode: "ld27j",
        name: "刘振星",
        headPortrait:
          "http://w1.hoopchina.com.cn/c5/46/4a/c5464a8c6e90c15e544fbd4662afbc23001.jpg",
        title: "神秘实力球员",
      },
      {
        playerId: 17392,
        playerCode: "z45lw",
        name: "韩曙光",
        resume: "s3赛季武汉站冠军 冠军赛选手",
        headPortrait:
          "http://i1.hoopchina.com.cn/user/3c/91/c4/3c91c45c57b7702007f86ddaffd0c2c6001.jpg@194h_194w_2e",
        title: "路人王7冠王，武汉直升机",
      },
      {
        playerId: 56317,
        playerCode: "md947",
        name: "郑逹炫",
        resume: "深圳大学cuba主力一级运动员2018年全国u17冠军",
        headPortrait:
          "http://w2.hoopchina.com.cn/48/d0/db/48d0dbdbbce5b4cdd3ad278727149c67001.jpg@194h_194w_2e",
        title: "深圳大学校队主力",
      },
      {
        playerId: 43826,
        playerCode: "jm355",
        name: "吴楚帆",
        resume: "广州赛区八强",
        headPortrait:
          "http://w3.hoopchina.com.cn/d9/56/a2/d956a2ec713e6ab6bb25b5f1de8c3775001.jpg@194h_194w_2e",
        title: "广州体育职业技术学院学生",
      },
      {
        playerId: 6874,
        playerCode: "6j4dp",
        name: "宋流轩",
        resume: "路人王17冠王。湖南省CUBA一级联赛四强",
        headPortrait:
          "http://w4.hoopchina.com.cn/e5/ee/31/e5ee3191b1c01456e734620df87ceee6001.jpg@194h_194w_2e",
        title: "路人王19冠王，变向狂魔",
      },
      {
        playerId: 82174,
        playerCode: "jodwd",
        name: "杨骜",
        resume: "中国三打三华南赛区第二名广州体育学院队员",
        headPortrait:
          "http://w4.hoopchina.com.cn/3f/15/01/3f15014a60900440f125c7dab21edaa0001.jpg@194h_194w_2e",
        title: "广州体育学院校队球员",
      },
      {
        playerId: 98782,
        playerCode: "5mgkr",
        name: "黄振豪",
        resume: "打篮球我喜欢",
        headPortrait:
          "http://w1.hoopchina.com.cn/84/83/18/848318e08adc61d31e978951777cdf4e001.jpg@194h_194w_2e",
        title: "神秘实力球员",
      },
      {
        playerId: 1176,
        playerCode: "nx20y",
        name: "柳杨杰",
        resume: "",
        headPortrait:
          "http://i1.hoopchina.com.cn/user/917/16834917/16834917_big_1.jpg@194h_194w_2e",
        title: "路人王15冠王，打铁哥",
      },
      {
        playerId: 8317,
        playerCode: "6mrj7",
        name: "梁嘉劲",
        resume:
          "2018东莞市长安镇篮球联赛奥普特扣篮冠军2020东莞市甲级联赛常平队主力",
        headPortrait:
          "http://w3.hoopchina.com.cn/70/55/a3/7055a36347cdc2fbc8ba64856966ed61001.jpg@194h_194w_2e",
        title: "2017东莞市长安奥普特扣篮冠军",
      },
      {
        playerId: 63113,
        playerCode: "y9mm0",
        name: "陈佳生",
        resume: "我很喜欢篮球，我想创造篮球生涯",
        headPortrait:
          "http://w3.hoopchina.com.cn/e2/64/f0/e264f0e16b3c31eff82a4eb8bfeb872d001.jpg@194h_194w_2e",
        title: "广州体育职业技术学院校队队长",
      },
      {
        playerId: 87523,
        playerCode: "k1dxm",
        name: "艾赛提吾拉·热合买提拉",
        resume: "进攻是最好的防守",
        headPortrait:
          "http://w1.hoopchina.com.cn/da/3c/dc/da3cdc379ad2c208df672482f62d1f3c001.jpg@194h_194w_2e",
        title: "神秘实力球员",
      },
      {
        playerId: 80301,
        playerCode: "ekgw3",
        name: "乔冠",
        resume: "篮球爱好者",
        headPortrait:
          "http://w1.hoopchina.com.cn/99/8e/17/998e17eafc92a03aaac0b103a68717e0001.jpg@194h_194w_2e",
        title: "神秘实力球员",
      },
      {
        playerId: 33290,
        playerCode: "oymjy",
        name: "白晶",
        resume: "国家一级篮球运动员",
        headPortrait:
          "http://w3.hoopchina.com.cn/5e/3a/9e/5e3a9ea4873d0e159d52335f8b28be12001.jpg@194h_194w_2e",
        title: "路人王29冠王，冷面刺客",
      },
      {
        playerId: 9097,
        playerCode: "6eygp",
        name: "姚育勤",
        resume:
          "路人王广州海口两站冠军，九月冠军赛成员，小快灵球员，来自华南师范大学",
        headPortrait:
          "http://w2.hoopchina.com.cn/9d/94/61/9d94619e81916550e6d2099b67468985001.jpg@194h_194w_2e",
        title: "路人王两冠王，小飞机",
      },
      {
        playerId: 9405,
        playerCode: "v0dm5",
        name: "李宏亮",
        resume: "路人王东莞站冠军，2018年jump10中国队成员，原汕头大学校队队员",
        headPortrait:
          "http://w2.hoopchina.com.cn/a9/a4/39/a9a4391291ceda4796eea0ef1695d59a001.jpg@194h_194w_2e",
        title: "路人王东莞站冠军，前汕大球员",
      },
      {
        playerId: 30566,
        playerCode: "oy4pr",
        name: "那尔太",
        resume: "华南理工大学校队球员，AUBA大学生联赛四强，路人王昆明站第四",
        headPortrait:
          "http://w2.hoopchina.com.cn/b7/be/d2/b7bed21b7c75da467d21297e466e8f94001.jpg@194h_194w_2e",
        title: "路人王广州站亚军，华南理工校队球员",
      },
      {
        playerId: 43826,
        playerCode: "jm355",
        name: "吴楚帆",
        resume: "广州赛区八强",
        headPortrait:
          "http://w3.hoopchina.com.cn/d9/56/a2/d956a2ec713e6ab6bb25b5f1de8c3775001.jpg@194h_194w_2e",
        title: "广州体育职业技术学院学生",
      },
      {
        playerId: 82174,
        playerCode: "jodwd",
        name: "杨骜",
        resume: "中国三打三华南赛区第二名广州体育学院队员",
        headPortrait:
          "http://w4.hoopchina.com.cn/3f/15/01/3f15014a60900440f125c7dab21edaa0001.jpg@194h_194w_2e",
        title: "广州体育学院校队球员",
      },
      {
        playerId: 113860,
        playerCode: "ld27j",
        name: "刘振星",
        headPortrait:
          "http://w1.hoopchina.com.cn/c5/46/4a/c5464a8c6e90c15e544fbd4662afbc23001.jpg",
        title: "神秘实力球员",
      },
      {
        playerId: 8317,
        playerCode: "6mrj7",
        name: "梁嘉劲",
        resume:
          "2018东莞市长安镇篮球联赛奥普特扣篮冠军2020东莞市甲级联赛常平队主力",
        headPortrait:
          "http://w3.hoopchina.com.cn/70/55/a3/7055a36347cdc2fbc8ba64856966ed61001.jpg@194h_194w_2e",
        title: "2017东莞市长安奥普特扣篮冠军",
      },
      {
        playerId: 33290,
        playerCode: "oymjy",
        name: "白晶",
        resume: "国家一级篮球运动员",
        headPortrait:
          "http://w3.hoopchina.com.cn/5e/3a/9e/5e3a9ea4873d0e159d52335f8b28be12001.jpg@194h_194w_2e",
        title: "路人王29冠王，冷面刺客",
      },
      {
        playerId: 43826,
        playerCode: "jm355",
        name: "吴楚帆",
        resume: "广州赛区八强",
        headPortrait:
          "http://w3.hoopchina.com.cn/d9/56/a2/d956a2ec713e6ab6bb25b5f1de8c3775001.jpg@194h_194w_2e",
        title: "广州体育职业技术学院学生",
      },
      {
        playerId: 9097,
        playerCode: "6eygp",
        name: "姚育勤",
        resume:
          "路人王广州海口两站冠军，九月冠军赛成员，小快灵球员，来自华南师范大学",
        headPortrait:
          "http://w2.hoopchina.com.cn/9d/94/61/9d94619e81916550e6d2099b67468985001.jpg@194h_194w_2e",
        title: "路人王两冠王，小飞机",
      },
      {
        playerId: 8317,
        playerCode: "6mrj7",
        name: "梁嘉劲",
        resume:
          "2018东莞市长安镇篮球联赛奥普特扣篮冠军2020东莞市甲级联赛常平队主力",
        headPortrait:
          "http://w3.hoopchina.com.cn/70/55/a3/7055a36347cdc2fbc8ba64856966ed61001.jpg@194h_194w_2e",
        title: "2017东莞市长安奥普特扣篮冠军",
      },
      {
        playerId: 113860,
        playerCode: "ld27j",
        name: "刘振星",
        headPortrait:
          "http://w1.hoopchina.com.cn/c5/46/4a/c5464a8c6e90c15e544fbd4662afbc23001.jpg",
        title: "神秘实力球员",
      },
      {
        playerId: 9405,
        playerCode: "v0dm5",
        name: "李宏亮",
        resume: "路人王东莞站冠军，2018年jump10中国队成员，原汕头大学校队队员",
        headPortrait:
          "http://w2.hoopchina.com.cn/a9/a4/39/a9a4391291ceda4796eea0ef1695d59a001.jpg@194h_194w_2e",
        title: "路人王东莞站冠军，前汕大球员",
      },
      {
        playerId: 30566,
        playerCode: "oy4pr",
        name: "那尔太",
        resume: "华南理工大学校队球员，AUBA大学生联赛四强，路人王昆明站第四",
        headPortrait:
          "http://w2.hoopchina.com.cn/b7/be/d2/b7bed21b7c75da467d21297e466e8f94001.jpg@194h_194w_2e",
        title: "路人王广州站亚军，华南理工校队球员",
      },
      {
        playerId: 33290,
        playerCode: "oymjy",
        name: "白晶",
        resume: "国家一级篮球运动员",
        headPortrait:
          "http://w3.hoopchina.com.cn/5e/3a/9e/5e3a9ea4873d0e159d52335f8b28be12001.jpg@194h_194w_2e",
        title: "路人王29冠王，冷面刺客",
      },
      {
        playerId: 82174,
        playerCode: "jodwd",
        name: "杨骜",
        resume: "中国三打三华南赛区第二名广州体育学院队员",
        headPortrait:
          "http://w4.hoopchina.com.cn/3f/15/01/3f15014a60900440f125c7dab21edaa0001.jpg@194h_194w_2e",
        title: "广州体育学院校队球员",
      },
      {
        playerId: 9405,
        playerCode: "v0dm5",
        name: "李宏亮",
        resume: "路人王东莞站冠军，2018年jump10中国队成员，原汕头大学校队队员",
        headPortrait:
          "http://w2.hoopchina.com.cn/a9/a4/39/a9a4391291ceda4796eea0ef1695d59a001.jpg@194h_194w_2e",
        title: "路人王东莞站冠军，前汕大球员",
      },
      {
        playerId: 113860,
        playerCode: "ld27j",
        name: "刘振星",
        headPortrait:
          "http://w1.hoopchina.com.cn/c5/46/4a/c5464a8c6e90c15e544fbd4662afbc23001.jpg",
        title: "神秘实力球员",
      },
      {
        playerId: 9097,
        playerCode: "6eygp",
        name: "姚育勤",
        resume:
          "路人王广州海口两站冠军，九月冠军赛成员，小快灵球员，来自华南师范大学",
        headPortrait:
          "http://w2.hoopchina.com.cn/9d/94/61/9d94619e81916550e6d2099b67468985001.jpg@194h_194w_2e",
        title: "路人王两冠王，小飞机",
      },
      {
        playerId: 82174,
        playerCode: "jodwd",
        name: "杨骜",
        resume: "中国三打三华南赛区第二名广州体育学院队员",
        headPortrait:
          "http://w4.hoopchina.com.cn/3f/15/01/3f15014a60900440f125c7dab21edaa0001.jpg@194h_194w_2e",
        title: "广州体育学院校队球员",
      },
    ],
  },
};
