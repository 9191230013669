import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"; 

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { getNewsDetail } from '../../api/pc'

import './style.scss'

export default function News(props) {
  const [newsId, setNewsId] = useState(props.match.params.nid);
  const [newsInfo, setNewContent] = useState({});

  const fetchNewsData = async (params) => {
    const result =  await getNewsDetail(params)
    setNewContent( result.data)
    console.log(result, '2333');
  }
  useEffect(() => {
    fetchNewsData({article_id: newsId});
  }, [])
  return (
    <div className="news-container">
      <Header />
      <div className="news">
        <div className="indexs">
          <Link to="/">首页</Link>><Link to="/newslist">资讯</Link>><span>{newsInfo.categoryTitle}</span>
        </div>
        <React.Fragment>
          <div className="news-title">{newsInfo.contentTitle}</div>
          <div className="news-info">
            <div className="author">{newsInfo.contentAuthor}</div>
            <div className="time">{newsInfo.contentDatetime}</div>
          </div>
          <div className="news-content" dangerouslySetInnerHTML={{__html:newsInfo.contentDetails}}/>
        </React.Fragment>
        
      </div>
      


      <Footer/>
    </div>
  )
}
